export const convertDataFile = (data, fileName, format = 'text/csv') => {
	let fileExtension = '.csv'

	if (format === 'application/pdf') {
		fileExtension = '.pdf'
	} else if (format === 'xlsx') {
		fileExtension = '.xlsx'
		format = 'application/vnd.ms-excel'
	} else if (format === 'js') {
		fileExtension = '.js'
	}

	const blob = new Blob([data], { type: format })
	const url = window.URL.createObjectURL(blob)
	const a = document.createElement('a')
	a.setAttribute('hidden', '')
	a.setAttribute('href', url)
	a.setAttribute('download', fileName + fileExtension)
	document.body.appendChild(a)
	a.click()
	document.body.removeChild(a)
}

export const dataArrayToCsv = (headers, data) => {
	const csvRows = []
	// getting headers.
	const headerValues = headers.map((header) => header.label)
	csvRows.push(headerValues.join(',')) // Push into array as comma separated values
	// Getting rows.
	for (const row of data) {
		const rowValues = headers.map((header) => {
			let value = row[header.key] || ''
			if (typeof (value) == 'string') {
				value = value.trim()
				if (value.lastIndexOf(',') === value.length - 1) {
					value = value.substring(0, value.length - 1)
				}
			}
			const escaped = ('' + value).replace(/"/g, '\\"') // To replace the unwanted quotes.
			return `"${escaped}"` // To escape the comma in a address like string.
		})
		csvRows.push(rowValues.join(',')) // Push into array as comma separated values.
	}
	return csvRows.join('\n') // To enter the next rows in the new line '\n'
}
