import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import Cookies from 'universal-cookie'
import { paths } from '@routes'
import { cookieKeys } from '@constants'

export const logout = (navigate, clicked = false) => {
	const cookies = new Cookies()
	Object.keys(cookieKeys).map((_item) => {
		cookies.remove(cookieKeys[_item], { path: '/' })
		return null
	})

	firebase
		.auth()
		.signOut()
		.then(
			function () {
				let pathname = '?path=' + window.location.pathname
				if (clicked || pathname.includes('login')) {
					pathname = ''
				}

				setTimeout(function () {
					if (navigate) navigate(paths.login + pathname, { replace: true })
				}, 10)
			},
			function (error) {
				// An error happened.
			}
		)
}
