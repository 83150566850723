import { MB_ROLES } from '@constants'

export const isClient = (role = '') => {
	return role?.includes('ORG_')
}

export const isSupplier = (roles = []) => {
	return roles.some((item) => item.role === 'SUPPLIER' && item.platform === 'MARKETPLACE' && item.approved)
}

export const isBuyer = (roles = []) => {
	return roles.some((item) => item.role === 'BUYER' && item.platform === 'MARKETPLACE' && item.approved)
}

export const isSuperAdmin = (role = '') => {
	return role === MB_ROLES.ADMIN || role === MB_ROLES.SUPER_ADMIN
}

export const isSupplyAdmin = (role = '', allowAdmin = true) => {
	return role === MB_ROLES.SUPPLY || (isSuperAdmin(role) && allowAdmin)
}
export const isSalesAdmin = (role = '', allowAdmin = true) => {
	return role === MB_ROLES.SALES || (isSuperAdmin(role) && allowAdmin)
}

export const isPrimaryAdmin = (user, users, isSales) => {
	if (!users) return false
	return users.some((item) => item.user_id === user.user_id && ((isSales && item.is_primary) || (!isSales && item.is_primary_supply)))
}

export const isFinanceAdmin = (role = '') => {
	return role === MB_ROLES.FINANCE
}