const hindi = {
	metalbook: 'METALBOOK',
	company: 'METALBOOK',
	admin: 'एडमिन',
	trade: 'व्यापार',
	aadhaar: 'आधार',
	all: 'सभी',
	accept: 'स्वीकार',
	accepted: 'स्वीकृत',
	action: 'गतिविधि',
	actions: 'गतिविधियां',
	active: 'गतिविधि',
	add: 'जोड़ें',
	address: 'पता',
	admins: 'एडमिन्स',
	again: 'दोबारा',
	allotted: 'आवंटित',
	amount: 'राशि',
	and: 'और',
	angles: 'एमएस एंगल्स',
	analytics: 'एनालिटिक्स',
	any: 'कोई',
	apply: 'आवेदन',
	approved: 'अनुमत',
	assign: 'नियुक्त',
	assigned: 'नियुक्त',
	attributes: 'गुण',
	auction: 'नीलामी',
	available: 'उपलब्ध',
	average: 'औसत',
	back: 'पीछे',
	batch: 'बैच',
	banner: 'बैनर',
	bank: 'बैंक',
	balance: 'शेष राशि',
	basic: 'बुनियादी',
	bid: 'बोली',
	bill: 'बिल',
	bills: 'बिल्स',
	bilti: 'लॉरी रसीद',
	blogs: 'ब्लॉग',
	brand: 'ब्रैंड',
	brands: 'ब्रांड्स',
	bsa: 'बीएसए',
	bspl: 'बीएसपीएल',
	buyer: 'क्रेता',
	business: 'व्यापार',
	by: 'द्वारा',
	calculation: 'गणना',
	capacity: 'क्षमता',
	cancel: 'रद्द करें',
	cancelled: 'रद्द',
	catalog: 'सूची',
	category: 'श्रेणी',
	categories: 'श्रेणियाँ',
	cast: 'कास्ट',
	calculate: 'कैलकुलेट',
	calculator: 'कैलकुलेटर',
	certificate: 'प्रमाणपत्र',
	change: 'बदलें',
	channel: 'एमएस चैनल',
	check: 'जाँच करें',
	city: 'शहर',
	clear: 'साफ़ करें',
	close: 'बंद करें',
	closed: 'बंद',
	closing: 'बंद हो रहा है',
	cloud: 'क्लाउड',
	country: 'देश',
	companies: 'कंपनियाँ',
	complete: 'पूर्ण',
	completed: 'पूरित',
	commodity: 'माल',
	comments: 'टिप्पणियाँ',
	confirm: 'पुष्टि करें',
	contact_us: 'संपर्क करें',
	continue: 'जारी रखें',
	consent: 'अनुमति',
	convert: 'बदलें',
	court: 'कोर्ट',
	current: 'मौजूदा',
	customer: 'ग्राहक',
	customise: 'अनुकूलित करें',
	create: 'उत्पन्न',
	created: 'रचित',
	credit: 'क्रेडिट',
	cross: 'क्रॉस',
	dashboard: 'डैशबोर्ड',
	data: 'डाटा',
	date: 'दिनांक',
	days: 'दिन',
	debit: 'जमा',
	defaults: 'चूक',
	delete: 'हटाना',
	delivered: 'पहुंच गया',
	delivery: 'डिलीवरी',
	deposit: 'जमा',
	description: 'विवरण',
	details: 'विवरण',
	destination: 'गंतव्य',
	director: 'निदेशक',
	directors: 'निदेशक',
	directorships: 'डिरेक्टरशिप्स',
	distance: 'दूरी',
	district: 'ज़िला',
	dispatch: 'डिस्पैच',
	document: 'दस्तावेज़',
	documents: 'दस्तावेज़',
	done: 'पूर्ण',
	download: 'डाउनलोड',
	driver: 'चालक',
	edit: 'एडिट',
	electricity: 'बिजली',
	email: 'ईमेल',
	email_id: 'ईमेल आईडी',
	employee: 'कर्मचारी',
	end: 'अंत',
	endorse: 'एंडोरस',
	endorsement: 'एंडोर्समेंट',
	enter: 'इन्क्वारी',
	enquiry: 'जाँच',
	enquiries: 'पूछताछ',
	entry: 'प्रवेश',
	e_statement: 'ई-स्टेटमेंट',
	errors: 'त्रुटियाँ',
	excel: 'एक्सेल',
	expiry: 'समाप्ति',
	export: 'एक्सपोर्ट',
	excluding: 'छोड़कर',
	ewaybill: 'ई-वे बिल',
	fabricator: 'फ़ेब्रिकेटर',
	feedback: 'प्रतिक्रिया',
	file: 'फ़ाइल',
	final: 'अंतिम',
	finance: 'वित्त',
	financials: 'वित्तीय स्थिति',
	first: 'पहला',
	for: 'फॉर',
	former: 'पूर्व',
	from: 'से',
	freight: 'भाड़ा',
	fsa: 'एफएसए',
	given: 'दिया गया',
	go: 'जाओ',
	get: 'प्राप्त करें',
	generate: 'उत्पन्न करें',
	gm: 'जीएम (%)',
	gmv: 'जीएमवी (सीआरएस)',
	group: 'समूह',
	grade: 'श्रेणी',
	gst: 'जीएसटी',
	gstin: 'जीएसटीआईएन',
	gstr1: 'जीएसटीआर-1',
	gstr2a: 'जीएसटीआर - 2ए',
	gstr3b: 'जीएसटीआर - 3बी',
	GST_18: 'जीएसटी (18%)',
	hash: 'हैश',
	hsn: 'एचएसएन',
	id: 'पहचान',
	ifc: 'आईएफसी',
	images: 'इमेजिस',
	inactive: 'निष्क्रिय',
	including: 'समेत',
	incoming: 'आने वाली',
	info: 'जानकारी',
	input: 'इनपुट',
	inventory: 'भंडार',
	invoice: 'इनवॉइस',
	invoices: 'इन्वॉइसेस',
	interest: 'ब्याज',
	intransit: 'रास्ते में',
	item: 'वस्तु',
	items: 'वस्तुएं',
	is: 'है',
	isflag: 'रेड फ्लैग?',
	km: 'किमी',
	kyc: 'केवाईसी',
	journey: 'यात्रा',
	journeys: 'यात्राएँ',
	language: 'भाषा',
	latest: 'नवीनतम',
	last: 'अंतिम',
	ledger: 'खाता बही',
	ledgers: 'खाता बही',
	length: 'लंबाई',
	list: 'सूची',
	listing: 'लिस्टिंग',
	listings: 'लिस्टिंगस',
	loaded: 'लदा हुआ',
	location: 'जगह',
	login: 'लॉग इन करें',
	logistic: 'लोजिस्टिक ',
	logistics: 'लोजिस्टिक्स',
	logout: 'लॉग आउट',
	lumpsum: 'एकमुश्त',
	max: 'अधिकतम',
	machine: 'मशीन',
	machines: 'मशीनें',
	make: 'निर्माण',
	manage: 'मैनेज',
	manufacturer: 'उत्पादक',
	material: 'सामग्री',
	mark: 'निशान',
	margin: 'अंतर',
	mca: 'एमसीए',
	message: 'संदेश',
	merge: 'मर्ज',
	mgw: 'एमजीडब्ल्यू',
	mgw_full: 'न्यूनतम गारंटीकृत वजन',
	mis: 'एमआईएस',
	min: 'न्यूनतम',
	mobile: 'मोबाइल',
	monthly: 'मासिक',
	moqq: 'मओक्यू',
	moq: 'MOQ (न्यूनतम आदेश मात्रा)',
	mm: 'मिमी',
	mt: 'मीट्रिक टन',
	my: 'मेरे',
	name: 'नाम',
	new: 'नया',
	news: 'समाचार',
	next: 'अगला',
	no: 'नंबर.',
	noc: 'अनापत्ति प्रमाण पत्र',
	number: 'नंबर',
	office: 'कार्यालय',
	ongoing: 'चल रहे',
	open: 'खुला',
	order: 'ऑर्डर',
	orders: 'ऑर्डर्स',
	organic: 'ऑर्गनिक',
	organisation: 'संगठन',
	origin: 'मूल',
	other: 'अन्य',
	otp: 'ओटीपी',
	outgoing: 'आउटगोइंग',
	owner: 'मालिक',
	owned: 'स्वामित्व',
	pan: 'पैन',
	passport: 'पासपोर्ट',
	password: 'पासवर्ड',
	particulars: 'विवरण',
	permanent: 'स्थायी',
	pending: 'लंबित',
	phone: 'फ़ोन',
	pickup: 'उठाना',
	pincode: 'पिन कोड',
	platform: 'प्लैटफ़ॉर्म',
	po: 'पी.ओ',
	primary: 'प्राथमिक',
	priority: 'प्राथमिकता',
	product: 'उत्पाद',
	profile: 'प्रोफाइल',
	process: 'प्रक्रिया',
	price: 'कीमत',
	prices: 'कीमते',
	purchase: 'खरीद',
	qty: 'मात्रा',
	quote: 'उद्धरण',
	quotes: 'उद्धरण',
	quotation: 'कोटेशन',
	quotations: 'कोटेशनस',
	reason: 'कारण',
	reject: 'अस्वीकार करें',
	rejection: 'अस्वीकार',
	received: 'प्राप्त',
	records: 'अभिलेख',
	refresh: 'रिफ्रेश',
	reference: 'संदर्भ',
	remark: 'टिप्पणी',
	remarks: 'टिप्पणियों',
	remove: 'निकाले ',
	rental: 'किराये',
	rented: 'किराए पर',
	report: 'रिपोर्ट',
	reports: 'रिपोर्टस',
	request: 'अनुरोध',
	requests: 'अनुरोध',
	required: 'आवश्यक',
	reset: 'रीसेट',
	rfq: 'आरएफक्यू',
	role: 'भूमिका',
	roles: 'भूमिकाए',
	row: 'पंक्ति',
	sample: 'नमूना',
	sale: 'बिक्री',
	sales: 'बिक्री',
	sanction: 'प्रतिबंध',
	sanctioned: 'स्वीकृत',
	save: 'सेव',
	score: 'अंक',
	scrap: 'स्क्रैप  ',
	see: 'देखें',
	search: 'खोज',
	select: 'चयन',
	selected: 'गिने चुने',
	sell: 'बेचें',
	setup: 'सेटअप',
	service: 'सर्विस',
	serviced: 'सर्विस्ड',
	serviceable: 'सर्विसएब्ल',
	share: 'शेयर करें',
	sheets: 'एचआर शीट्स',
	sku: 'एसकेयू',
	source: 'स्रोत',
	supply: 'सप्लाई',
	start: 'शुरू',
	state: 'स्टेट',
	statement: 'स्टेटमेंट',
	status: 'स्तर',
	stock: 'भंडार',
	stop: 'रोकें',
	sub: 'विषय',
	submit: 'प्रस्तुत',
	submitted: 'प्रस्तुत',
	supplier: 'सप्लायर',
	suppliers: 'सप्लायरस',
	partner: 'साझेदार',
	past: 'अतीत',
	photo: 'तस्वीर',
	photos: 'तस्वीरें',
	personal: 'निजी',
	tc: 'टीसी',
	tds: 'टीडीएस',
	test: 'परीक्षण',
	tenure: 'कार्यकाल',
	thickness: 'मोटाई (मिमी)',
	thicknesss: 'मोटाई',
	tnc: 'टी एंड सी',
	to: 'को',
	tools: 'टूल्स',
	ton: 'टन',
	trader: 'व्यापारी',
	transaction: 'लेन-देन',
	trip: 'यात्रा',
	total: 'कुल',
	type: 'प्रकार',
	unaccept: 'अस्वीकार्य',
	underwriting: 'अंडरराइटिंग',
	units: 'यूनिट्स',
	upcoming: 'आगामी',
	update: 'अपडेट',
	upload: 'अपलोड',
	uploaded: 'उप्लोडेड',
	url: 'यूआरएल',
	user: 'उपयोगकर्ता',
	users: 'उपयोगकर्ता',
	utility: 'उपयोगिता',
	value: 'कीमत',
	validity: 'वैधता',
	vehicle: 'वाहन',
	verify: 'सत्यापित',
	verified: 'सत्यापित',
	vernacularize: 'वर्नाक्यूलराइज़',
	video: 'वीडियो',
	view: 'देखे',
	visibility: 'दृश्यता',
	website: 'वेबसाइट',
	weight: 'वजन',
	weightage: 'वेटेज',
	winning: 'जीत',
	x: 'एक्स',
	yes: 'हाँ',
	your: 'आपके',
	waste: 'व्यर्थ',
	zoho: 'जोहो',
	aadhar_required: 'आधार जरूरी है',
	active_auction: 'सक्रिय नीलामी',
	accept_quote: 'कोट स्वीकार करें',
	add_address: 'पता जोड़ें',
	add_item: 'वस्तु जोड़ें',
	add_new_item: 'नए सामान को जोड',
	add_remarks: 'टिप्पणी जोड़ें',
	address_book: 'पता पुस्तिका',
	admins_message: 'व्यवस्थापक संदेश',
	auction_id: 'नीलामी आईडी',
	add_new_quote: 'नया उद्धरण जोड़ें',
	all_auction: 'सभी नीलामी',
	all_requests: 'सभी अनुरोध',
	allotted_weight: 'आवंटित वजन',
	amount_lakh: 'राशि लाख में',
	apply_credit: 'क्रेडिट के लिए आवेदन करें',
	available_items: 'उपलब्ध वस्तुएं',
	assign_enquiry: 'जांच सौंपें',
	assign_auction: 'नीलामी असाइन करें',
	associated_suppliers: 'संबंधित सप्लायरस',
	average_costmt: 'औसत लागत / मीट्रिक टन',
	back_orders: 'आदेश पर वापस जाओ',
	back_factories: 'कारखानों पर वापस जाओ',
	back_machines: 'मशीनस पर वापस जाओ',
	beams_joist: 'एमएस बीम्स | जॉइस्ट',
	best_quotes: 'सबसे अच्छा कोट',
	best_prices: 'उत्तम दाम',
	bid_accepted: 'बोली स्वीकार की गई है',
	bid_auction: 'सामग्री पर बोली',
	bid_details: 'बोली विवरण',
	bid_successfully: 'बोली %s सफलतापूर्वक।',
	bills_generated: 'उत्पन्न बिल',
	business_sales: 'व्यवसाय (बिक्री)',
	business_purch: 'व्यापार (खरीद)',
	business_purchase: 'व्यापार खरीद',
	buyer_contact: 'क्रेता संपर्क',
	buyer_quote: 'क्रेता बोली',
	cancel_enq: 'इन्क्वारी रद्द करें',
	category_name: 'श्रेणी नाम',
	category_id: 'श्रेणी ID',
	change_address: 'पता बदलें',
	company_name: 'कंपनी का नाम',
	concerned_manager: 'संबंधित प्रबंधक',
	converted_order: 'आदेश में परिवर्तित',
	close_enq: 'इन्क्वारी बंद करें',
	closes_on: 'बंद होगा',
	cost_mt: 'लागत मीट्रिक टन',
	connect_zoho: 'ज़ोहो से कनेक्ट करें',
	create_auction: 'नीलामी बनाएँ',
	create_enquiry: 'पूछताछ बनाएं',
	create_category: 'श्रेणी बनाएँ',
	create_product: 'उत्पाद बनाएँ',
	credit_book: 'क्रेडिट बुक',
	credit_discount: 'क्रेडिट (छूट)',
	daily_capacity: 'दैनिक क्षमता',
	delivery_location: 'वितरण का स्थान',
	delivery_receipt: 'वितरण रसीद (पीओडी)',
	digit_gstin: '15 अंकों का जीएसटीआईएन',
	digits_otp: '6 अंकों का ओटीपी',
	digital_experience: 'डिजिटल अनुभव',
	dharamkata_slip: 'वजन पर्ची',
	dispatch_location: 'प्रेषण स्थान',
	district_court: 'जिला अदालत',
	drt_court: 'डॉ कोर्ट',
	edit_quote: 'उद्धरण संपादित करें',
	end_date: 'अंतिम तिथि',
	ex_delhi: 'दिल्ली',
	ex_raipur: 'रायपुर',
	ex_mumbai: 'मुंबई',
	expected_delivery: 'अपेक्षित डिलीवरी',
	enter_name: 'नाम डालें',
	my_profile: 'मेरी प्रोफाइल',
	enquiry_date: 'इन्क्वारी की तारीख',
	enquiry_id: 'पूछताछ आईडी',
	enquiry_items: 'इन्क्वारी के आइटम',
	evaluation_criteria: 'मूल्यांकन के मानदंड',
	expiry_date: 'समाप्ति तिथि',
	excess_ctc: 'अतिरिक्त सीटीसी @ 18% (लाख)',
	factory: 'कारखाना',
	factory_name: 'कारखाने का नाम',
	final_scores: 'अंतिम अंक',
	final_report: 'अंतिम रिपोर्ट',
	finalise_quote: 'उद्धरण को अंतिम रूप दें',
	finalize_quote: 'उद्धरण को अंतिम रूप दें',
	forgot_pwd: 'पासवर्ड भूल गए?',
	fullfill_enquiry: 'पूछताछ पूरी करें',
	go_now: 'अब जाओ',
	cr_coil: 'सीआर कॉइल',
	gp_coil: 'जीपी कॉइल',
	gps_link: 'जीपीएस लिंक',
	gst_18: 'जीएसटी (18%)',
	gstin_required: 'जीएसटीआईएन आवश्यक है',
	hsn_code: 'एचएसएन कोड',
	has_report: '#प्रतिवेदन',
	high_court: 'हाईकोर्ट',
	items_cost: 'वस्तुओं की लागत',
	image_able: 'फोटो उपलब्ध',
	image_available: '%s फोटो %s उपलब्ध',
	images_available: 'तस्वीरें उपलब्ध हैं',
	in_review: 'रिव्यू में',
	invalid_pincode: 'अमान्य पिनकोड',
	inventory_details: 'इन्वेंटरी विवरण',
	invoice_amount: 'इनवॉइस की राशि',
	invoice_no: 'चालान नंबर',
	invoice_number: 'इनवॉइस संख्या',
	is_top: 'मैं रुकता हूँ?',
	input_products: 'इनपुट उत्पाद',
	order_id: 'ऑर्डर आईड़ी',
	output_products: 'आउटपुट उत्पाद',
	list_inventory: 'लिस्ट इन्वेंटरी',
	listing_id: 'लिस्टिंग आईडी',
	litigation_detail: 'मुकदमेबाजी विवरण',
	logistics_charges: 'लोजिस्टिक्स चार्जेज',
	logistics_id: 'लोजिस्टिक्स आईडी',
	logistics_items: 'लोजिस्टिक्स आइटम',
	logistics_requests: 'लोजिस्टिक्स रिक्वेस्ट्स',
	logistics_order: 'लोजिस्टिक्स ऑर्डर',
	logistics_partner: 'लोजिस्टिक्स भागीदार',
	logistics_price: 'लोजिस्टिक्स मूल्य',
	logistics_support: 'लोजिस्टिक्स समर्थन',
	machine_name: 'मशीन का नाम',
	machine_description: 'मशीन का विवरण',
	machines_count: 'मशीनें की गिनती ',
	machine_make: 'मशीन ब्रैंड',
	machine_type: 'मशीन का प्रकार',
	manage_auctions: 'मैनेज ऑक्शन्स',
	manage_enquiries: 'मैनेज एनक्विरिएस',
	manage_factories: 'कारखानों का प्रबंधन करें',
	manage_orders: 'आदेश प्रबंधित करें',
	manage_enquiry_orders: 'पूछताछ आदेश प्रबंधित करें',
	manage_auction_orders: 'नीलामी आदेश प्रबंधित करें',
	manage_purchase_orders: 'खरीद आदेश प्रबंधित करें',
	manage_supply_orders: 'आपूर्ति आदेश प्रबंधित करें',
	manage_logistics_orders: 'लोजिस्टिक्स आदेश प्रबंधित करें',
	max_lth: 'अधिकतम लंबाई',
	max_score: 'अधिकतम अंक',
	merge_user_success: 'उपयोगकर्ता को सफलतापूर्वक मर्ज करें',
	media_url: 'मीडिया यूआरएल',
	moq_mt: 'एमओक्यू (एमटी)',
	mob_num: 'मोबाइल नंबर',
	minimum_order_quantity_mt: 'न्यूनतम ऑर्डर मात्रा (एमटी)',
	my_auctions: 'मेरी नीलामी',
	my_enquiries: 'मेरी पूछताछ',
	name_required: 'नाम आवश्यक है',
	nclt_court: 'एनसीएलटी कोर्ट',
	num_machines: 'मशीनों की संख्या',
	non_associated_suppliers: 'गैर संबद्ध आपूर्तिकर्ता',
	not_available: 'उपलब्ध नहीं है',
	not_data_found: 'डेटा नहीं मिला',
	no_auctions_found: 'कोई नीलामी नहीं मिली',
	no_documents: 'कोई दस्तावेज नहीं',
	no_machines_added_yet: 'अभी तक कोई मशीन नहीं जोड़ी गई है',
	ordered: 'ऑर्डर दिया',
	order_ids: 'ऑर्डर आईडी',
	order_items: 'ऑर्डर आइटम',
	order_lost: 'ऑर्डर खो गया',
	order_summary: 'आदेश सारांश',
	order_placed: 'आर्डर दे दिया गया है',
	open_auction: 'खुली नीलामी',
	open_enquiries: 'खुली पूछताछ',
	opening_price: 'शुरुआती कीमत',
	ordered_weight: 'आदेश दिया वजन',
	owner_noc: 'मकान मालिक से पुष्टि',
	payment_summary: 'भुगतान सारांश',
	ppgi_sheets: 'पीपीजीआई शीट्स',
	preferred_brand: 'पसंदीदा ब्रांड',
	price_validity: 'मूल्य वैधता',
	promote_trader: 'आपूर्तिकर्ता को बढ़ावा दें',
	product_name: 'उत्पादों',
	product_id: 'उत्पाद आयडी',
	price_range: 'मूल्य सीमा (मीट्रिक टन)',
	preferred_category: 'पसंदीदा श्रेणी',
	purchase_invoices: 'खरीद चालान',
	purchase_order: 'खरीद आदेश',
	order_date: 'ऑर्डर की तारीख',
	quotation_amount: 'कोटेशन राशि',
	quote_accepted: 'उद्धरण स्वीकार कर लिया गया है',
	quote_per_kg: 'भाव प्रति किलो',
	quoted_amount_x: 'उद्धृत राशि (पूर्व)',
	quote_received: 'उद्धरण प्राप्त',
	quotes_submitted: 'प्रस्तुत उद्धरण',
	raise_request: 'अनुरोध उठाएं',
	rate_validity: 'दर वैधता',
	reactivate: 'पुन:सक्रिय करें',
	receiving: 'रसीद',
	request_logistics: 'अनुरोध रसद',
	request_kyc: 'केवाईसी का अनुरोध करें',
	received_warehouse: 'गोदाम पर प्राप्त हुआ',
	ref_no: 'प्रसंग संख्या',
	rent_agreement: 'किराया समझौता',
	required_weight: 'आवश्यक वजन',
	rera_court: 'रेरा कोर्ट',
	round_bars: 'एमएस राउंड बार्स',
	running_order_book: 'रनिंग ऑर्डर बुक',
	sale_invoices: 'बिक्री चालान',
	sale_input: 'बिक्री इनपुट',
	sale_team: 'विक्रय टीम',
	sale_order: 'विक्रय ऑर्डर',
	sanction_required: 'स्वीकृति आवश्यक है',
	scoring_criteria: 'स्कोरिंग मानदंड',
	see_quote: 'उद्धरण देखें',
	see_quotes: 'उद्धरण देखें',
	search_product: 'उत्पाद को खोजें',
	set_sales_primary: 'बिक्री प्राथमिक के रूप में सेट करें',
	set_supply_primary: 'आपूर्ति प्राथमिक के रूप में सेट करें',
	select_a_category: 'श्रेणी चुनें',
	select_a_partner: 'भागीदार का चयन करें',
	select_a_product: 'उत्पाद चुनो',
	select_a_type: 'प्रकार चुनें',
	select_address: 'पता चुनें',
	select_brand: 'ब्रांड चुनें',
	select_brands: 'ब्रांडों का चयन करें',
	select_buyer: 'खरीदार का चयन करें',
	select_category: 'श्रेणी चुनना',
	select_date: 'तारीख़ चुनें',
	select_employee: 'कर्मचारी का चयन करें',
	select_employee_s: 'कर्मचारियों का चयन करें',
	select_status: 'स्थिति का चयन करें',
	select_partner: 'भागीदार का चयन करें',
	select_process: 'प्रक्रिया का चयन करें',
	select_product: 'उत्पाद का चयन करें',
	select_role: 'भूमिका चुनें',
	select_type: 'प्रकार चुनें',
	select_user: 'उपयोगकर्ता का चयन करें',
	selfattested: 'स्व अभिप्रमाणित',
	servicable_weight: 'सेवा योग्य वजन',
	serviced_weight: 'सेवित वजन',
	serviced_weight_mt: 'सेवित वजन (एमटी)',
	share_enquiry: 'शेयर इन्क्वारी',
	share_enquiry_whapps: 'शेयर इन्क्वारी (व्हाट्सप्प)',
	shareholding_pattern: 'शेयरहोल्डिंग पैटर्न',
	sheets_plates: 'एमएस शीट्स / प्लेट्स',
	shipping_address: 'शिपिंग पता',
	shipping_details: 'शिपिंग की जानकारियां',
	shipping_location: 'शिपिंग स्थान',
	sign_in: 'साइन इन',
	sign_up: 'साइन अप',
	start_date: 'आरंभ करने की तिथि',
	supplier_by_inventory: 'आपूर्तिकर्ता सूची',
	submit_quote: 'उद्धरण जमा करें',
	supreme_court: 'सुप्रीम कोर्ट',
	square_pipe: 'एमएस स्क्वायर पाइप',
	other_details: 'अन्य विवरण',
	privacy_policy: 'गोपनीयता नीति',
	process_name: 'प्रक्रिया नाम',
	loading_employees: 'कर्मचारी लोड हो रहे हैं...',
	tc_reqd: 'टीसी की आवश्यकता है?',
	tc_avl: 'टीसी उपलब्ध है?',
	tc_document: 'टीसी दस्तावेज़',
	tenure_required: 'कार्यकाल की आवश्यकता है',
	terms_service: 'सेवा की शर्तें',
	test_certificate: 'परीक्षण प्रमाणपत्र',
	thumbnail_url: 'थंबनेल Url',
	tmt_bars: 'एमएस टीएमटी बार्स',
	tribunal_court: 'ट्रिब्यूनल कोर्ट',
	total_amount: 'कुल राशि',
	total_amount_for: 'कुल राशि (के लिए)',
	total_balance: 'कुल शेष',
	total_bills: 'कुल बिल',
	total_business: 'कुल कारोबार',
	total_credit: 'कुल क्रेडिट',
	total_debit: 'कुल डेबिट',
	total_items: 'कुल सामान',
	total_gst: 'कुल जीएसटी',
	total_quote: 'कुल बोली',
	total_sales: 'कुल बिक्री',
	total_weight: 'कुल वजन',
	total_transactions: 'कुल लेन-देन',
	track_order: 'ट्रैक ऑर्डर',
	tracking_link: 'ट्रैकिंग लिंक',
	transaction_type: 'सौदे का प्रकार',
	pan_required: 'पैन आवश्यक है',
	process_type: 'प्रक्रिया प्रकार',
	selected_items: 'चयनित उत्पाद',
	ordered_weight_mt: 'आदेशित वजन (एमटी)',
	upcoming_auction: 'आगामी नीलामी',
	update_exit: 'अपडेट करें और बाहर निकलें',
	update_quote: 'अपडेट कोट',
	upload_again: 'दोबारा अपलोड करें',
	upload_images: 'तश्वीरें अपलोड करो',
	used: 'इस्तेमाल किया गया',
	used_weight: 'प्रयुक्त वजन (एमटी)',
	user_name_required: 'उपयोगकर्ता नाम आवश्यक है',
	verify_auction: 'नीलामी सत्यापित करें',
	verified_suppliers: '1000+ सत्यापित आपूर्तिकर्ता',
	view_enquiry: 'इन्क्वारी देखें',
	view_quote: 'उद्धरण देखें',
	your_bids: 'आपकी बोलियां',
	warehouse_capacity: 'गोदाम क्षमता (एमटी)',
	weight_mt: 'वजन (एमटी)',
	weight_mt_units: 'वजन (एमटी)',
	wlcm_to: 'स्वागत है',
	zoho_contact: 'ज़ोहो संपर्क आईडी',
	zoho_customer: 'ज़ोहो ग्राहक आईडी',
	add_new_address: 'नया पता जोड़ें',
	add_buyer_items: 'खरीदार और वस्तुएं जोड़ें',
	add_dispatch_address: 'प्रेषण पता जोड़ें',
	add_item_details: 'आइटम विवरण जोड़ें',
	add_trip_success: 'यात्रा सफलतापूर्वक जोड़ें',
	added_waste_item: 'अपशिष्ट वस्तु को सफलतापूर्वक जोड़ा गया',
	after_process_images: 'प्रक्रिया के बाद तस्वीरें',
	after_process_photos: 'प्रक्रिया के बाद तस्वीरें',
	alloted_weight_units: 'आवंटित वजन (एमटी)',
	attribute_is_mandatory: 'विशेषता अनिवार्य है',
	average_past_delays: 'औसत विगत विलंब',
	back_cloud_orders: 'क्लाउड ऑर्डर पर वापस',
	back_manage_orders: 'मैनेज ऑर्डर पर वापस',
	before_process_img: 'प्रक्रिया के पहले तस्वीरें',
	before_process_photos: 'प्रक्रिया के पहले तस्वीरें',
	bid_valid_till: 'बोली की वैध',
	brand_of_machine: 'मशीन का ब्रांड',
	bspl_req_sent: 'बीएसपीएल क्रेडिट अनुरोध भेज दिया गया है',
	capacity_per_day_mt: 'एमटी में प्रति दिन क्षमता',
	create_factory_successfully: 'फैक्टरी सफलतापूर्वक बनाई गई',
	create_underwriting_successfully: 'नई रिपोर्ट सफलतापूर्वक सबमिट की गई',
	commodity_prices_successfully: 'कमोडिटी की कीमतें सफलतापूर्वक जोड़ी गईं',
	contact_num_required: 'संपर्क नंबर आवश्यक है',
	click_manage_enq: 'इन्क्वारी मैनेज करने के लिए क्लिक करें',
	confirm_upgrade_user: 'पुष्टि करें कि क्या आप उपयोगकर्ता को %s के रूप में अपग्रेड करना चाहते हैं?',
	convert_to_order: 'ऑर्डर में कनवर्ट करें',
	converted_to_order: 'ऑर्डर में परिवर्तित',
	cost_per_mt: 'लागत/एमटी',
	custom_machine_details: 'कस्टम मशीन विवरण',
	description_for_machine: 'मशीन के लिए विवरण',
	delete_bill_successfully: 'बिल को सफलतापूर्वक हटाएं',
	delete_ledger_successfully: 'लेजर को सफलतापूर्वक हटाएं',
	dispatch_weighment_slip: 'डिस्पैच वेट स्लिप',
	distance_calculator_success: 'दूरी कैलक्यूलेटर सफलतापूर्वक अपलोड किया गया है',
	drop_weighment_slip: 'ड्रॉप वजन पर्ची',
	during_process_images: 'प्रक्रिया के दौरान तस्वीरें',
	during_process_photos: 'प्रक्रिया के दौरान तस्वीरें',
	edit_item_details: 'आइटम विवरण संपादित करें',
	enquiry_open_till: 'पूछताछ ओपन तक',
	enquiry_cannot_edited: 'इन्क्वारी संपादित नहीं की जा सकती',
	enquiry_updated_success: 'इन्क्वारी सफलतापूर्वक अपडेट की गई',
	enter_drop_date: 'ड्रॉप तिथि दर्ज करें',
	enter_destination_pincode: 'गंतव्य पिनकोड दर्ज करें',
	enter_digit_gstin: 'कृपया एक मान्य 15 अंकों का GSTIN दर्ज करें',
	enter_digit_otp: 'कृपया 6 अंकों का ओटीपी दर्ज करें',
	enter_digit_aadhaar: 'कृपया एक मान्य 12 अंकों का आधार दर्ज करें',
	enter_location_pincode: 'स्थान पिनकोड दर्ज करें',
	enquiry_shared_success: 'इन्क्वारी सफलतापूर्वक साझा की गई',
	enter_num_days: 'दिनों की संख्या दर्ज करें',
	enter_pan_gst: 'कृपया एक मान्य पैन या जीएसटी दर्ज करें',
	enter_request_amount: 'अनुरोध राशि दर्ज करें',
	enter_request_tenure: 'अनुरोध अवधि दर्ज करें (दिन का)',
	enter_serviced_weight: 'सेवित वजन दर्ज करें',
	enter_ordered_weight: 'ऑर्डर वजन दर्ज करें',
	enter_serviceable_weight: 'सेवा योग्य वजन दर्ज करें',
	enter_val_meters: 'मीटर में मान दर्ज करें',
	enter_val_millimeters: 'मिलीमीटर में मान दर्ज करें',
	enter_origin_pincode: 'मूल पिनकोड दर्ज करें',
	enter_organisation_gst: 'कृपया संगठन जीएसटी संख्या दर्ज करें',
	enter_max_price: 'अधिकतम मूल्य दर्ज करें',
	enter_min_price: 'न्यूनतम मूल्य दर्ज करें',
	enter_logistics_order: 'लोजिस्टिक्स ऑर्डर दर्ज करें',
	enter_valid_email: 'कृपया एक मान्य ईमेल दर्ज करें',
	enter_valid_pan: 'कृपया मान्य 10 अंकों का पैन दर्ज करें',
	enter_valid_num: 'कृपया सही अंक दर्ज करें',
	enter_valid_url_link: 'कृपया एक वैध यूआरएल लिंक दर्ज करें',
	enter_your_mobile_num: 'अपना मोबाइल नंबर दर्ज करे',
	enter_your_organisation: 'अपने संगठन का विवरण दर्ज करें',
	everyday_utilisation_machine: 'मशीन का दैनिक उपयोग',
	general_machine_details: 'सामान्य मशीन विवरण',
	fabrication_invoice_added: 'फेब्रिकेशन इनवॉइस सफलतापूर्वक जोड़ा गया',
	factory_created_successfully: 'फैक्टरी सफलतापूर्वक बनाई गई',
	factory_order_created: 'फ़ैक्टरी ऑर्डर सफलतापूर्वक बनाया गया',
	factory_order_updated: 'फैक्टरी ऑर्डर सफलतापूर्वक अपडेट किया गया',
	facility_flexible_payments: 'ऋण सुविधा/फ्लेक्सिबल भुगतान',
	failed_created_enquiry: 'इन्क्वारी बनाने में विफल',
	failed_updated_enquiry: 'इन्क्वारी अपडेट में विफल',
	fastest_response_time: 'सबसे तेज प्रतिक्रिया ',
	fetch_past_records: 'पिछले रिकॉर्ड प्राप्त करें',
	fetch_zoho_contid: 'ज़ोहो संपर्क आईडी प्राप्त करें',
	finance_feedback_update: 'वित्त प्रतिक्रिया सफलतापूर्वक अपडेट',
	fsa_file_upload: 'एफएसए फाइल अपलोड कर दी गई है',
	have_tc: 'क्या आपके पास इस उत्पाद के लिए परीक्षण प्रमाणपत्र है?',
	input_output_products: 'इनपुट और आउटपुट उत्पाद',
	input_coil_thickness: 'इनपुट कॉइल मोटाई',
	input_coil_width: 'इनपुट कॉइल चौड़ाई',
	input_product_ctgy: 'इनपुट उत्पाद श्रेणियाँ',
	input_order_items: 'इनपुट ऑर्डर आइटम',
	inventory_batch_no: 'इन्वेंटरी बैच संख्या',
	inventory_from_factory: 'कारखाने से इन्वेंटरी',
	inventory_updated_successfully: 'इन्वेंटरी सफलतापूर्वक अपडेट की गई',
	invoice_added_successfully: 'इनवॉइस सफलतापूर्वक जोड़ा गया',
	invoice_successfully_endorsed: 'इनवॉइस सफलतापूर्वक एंडोरसेद है',
	invoice_uploaded_successfully: 'इनवॉइस सफलतापूर्वक अपलोड किया गया',
	output_product_ctgy: 'आउटपुट उत्पाद श्रेणियाँ',
	output_order_items: 'आउटपुट ऑर्डर आइटम',
	update_account_details: 'खाता विवरण अपडेट करें',
	update_item_details: 'आइटम विवरण अपडेट करें',
	update_organisation_details: 'संगठन विवरण अपडेट करें',
	is_final_quote: 'क्या यह अंतिम उद्धरण है?',
	is_tc_reqd: 'क्या टेस्ट सर्टिफिकेट जरूरी है?',
	is_tc_available: 'क्या टीसी उपलब्ध है?',
	items_enquiry_order: 'इन्क्वारी/ऑर्डर से आइटम',
	login_other_num: 'दूसरे नंबर के साथ लॉगिन करें',
	login_with_email: 'ईमेल के साथ लॉगिन करें',
	login_with_mobile: 'मोबाइल के साथ लॉगिन करें',
	logistics_quotation: 'लोजिस्टिक्स कोटशन',
	logistics_quotation_mt: 'लोजिस्टिक्स_कोटशन  / एमटी',
	mb_ref_no: 'एमबी रेफ नO:',
	mb_ref_number: 'एमबी संदर्भ संख्या',
	max_coil_weight_mt: 'मैक्स कॉइल वेट (एमटी)',
	machine_capacity_day_mt: 'प्रति दिन मशीन क्षमता (एमटी)',
	monthly_capacity_mt: 'मासिक क्षमता (एमटी)',
	monthly_conversion_chart: 'मासिक पूछताछ -> चार्ट',
	monthly_quotes_conversion_chart: 'मासिक उद्धरण -> चार्ट',
	monthly_sale_purchase_chart: 'मासिक बिक्री/खरीद तुलना',
	max_input_length_m: 'अधिकतम लंबाई (मी)',
	max_input_width_mm: 'अधिकतम इनपुट चौड़ाई (मिमी)',
	max_diagonal_tolerance_mm: 'अधिकतम डायगोनल टॉलरेंस',
	max_input_thinkness_mm: 'अधिकतम इनपुट मोटाई (मिमी)',
	max_lth_tolerance_mm: 'अधिकतम लंबाई टॉलरेंस (मिमी)',
	max_available: 'अधिकतम उपलब्ध %s',
	max_available_mt: 'अधिकतम उपलब्ध %s एमटी',
	max_weight_is_mt: 'अधिकतम वजन %s एमटी है',
	max_canbe: 'अधिकतम %s हो सकता है',
	max_canbe_mt: 'अधिकतम %s एमटी हो सकता है',
	max_lth_mtr: 'मीटर में अधिकतम लंबाई',
	min_diagonal_tolerance_mm: 'न्यूनतम डायगोनल टॉलरेंस (मिमी)',
	min_input_thinkness_mm: 'न्यूनतम इनपुट मोटाई (मिमी)',
	min_input_width_mm: 'न्यूनतम इनपुट चौड़ाई (मिमी)',
	min_lth_tolerance_mm: 'न्यूनतम लंबाई टॉलरेंस (मिमी)',
	mob_num_required: 'मोबाइल नंबर की आवश्यकता है',
	no_addresses_found: 'कोई पता नहीं मिला',
	min_bid_amount_is_mt: 'मिनिनम बोली राशि %s एमटी है',
	moq_is_mt: 'न्यूनतम ऑर्डर मात्रा%s एमटी है',
	new_access_requested: 'नई एक्सेस का अनुरोध किया',
	no_factory_found_id: 'कोई कारखाना आईडी %s के साथ नहीं मिला',
	no_machine_found_id: 'कोई मशीन आईडी %s के साथ नहीं मिली',
	no_invtry_add_yet: 'कोई इन्वेंट्री अभी तक नहीं जोड़ी गई है',
	no_img_add_yet: 'कोई तस्वीरें अभी तक नहीं जोड़ा गया',
	no_inventory_available: 'कोई इन्वेंटरी उपलब्ध नहीं है। कृपया एक अलग कारखाना चुनें।',
	no_items_add_yet: 'अभी तक कोई आइटम नहीं जोड़ा गया',
	no_items_order_enquiry: 'इस ऑर्डर/इन्क्वारी में कोई भी आइटम नहीं मिला',
	no_order_found_id: 'आईडी %s के साथ कोई ऑर्डर नहीं मिला',
	no_of_pages: 'पृष्ठों की संख्या ',
	no_temp_found: 'कोई टेम्पलेट नहीं मिला',
	no_test_certificate: 'कोई परीक्षण प्रमाण पत्र नहीं जोड़ा गया',
	num_of_days: 'दिनों की संख्या',
	order_amount: 'ऑर्डर की राशि',
	order_weight: 'ऑर्डर का भार',
	one_stop_solution: 'एक-स्टॉप समाधान',
	order_journey_updated: 'ऑर्डर यात्रा सफलतापूर्वक अपडेट की गई',
	original_invoice_number: 'मूल इनवॉइस नंबर ',
	only_aplha_special_char: 'केवल अल्फ़ान्यूमेरिक और विशेष वर्ण जैसे _ $ # @ की अनुमति है',
	req_credit_sent: 'अनुरोध क्रेडिट भेज दिया गया है',
	set_as_primary: 'प्राथमिक के रूप में सेट करें',
	start_add_items: 'आइटम जोड़ना शुरू करें',
	select_a_brand: 'एक ब्रांड का चयन करें',
	select_closing_reason: 'समापन कारण का चयन करें',
	select_machine_process: 'मशीन प्रक्रिया का चयन करें',
	select_inventroy_factory: 'कारखाने से इन्वेंटरी का चयन करें',
	select_order_date: 'आदेश तिथि का चयन करें',
	select_quote_type: 'कोट प्रकार का चयन करें',
	select_rejection_reason: 'अस्वीकृति कारण 1 का चयन करें',
	select_items_enquiry_order: 'इन्क्वारी/ऑर्डर से आइटम का चयन करें',
	serviced_weight_kg: 'सेवित वज़न (किग्रा)',
	serviced_weight_sku: 'प्रति एसकेयू सेवित वजन',
	stock_img_uploaded: 'स्टॉक तस्वीरें को सफलतापूर्वक अपलोड किया गया',
	stock_photo_unavailable: 'स्टॉक फोटो अनुपलब्ध',
	pancard_upload_success: 'पैन कार्ड का विवरण सफलतापूर्वक सहेजा गया',
	upload_success: 'फ़ाइल सफलतापूर्वक अपलोड की गई',
	price_per_kg: 'मूल्य प्रति किग्रा',
	price_per_ton: 'मूल्य/टन',
	price_validity_moq: 'मूल्य वैधता और एमओक्यू',
	price_too_low: 'कीमत बहुत कम है',
	point_contact_name: 'संपर्क का नाम',
	point_contact_required: 'संपर्क का नाम की आवश्यकता है',
	point_contact_mobile_num: 'संपर्क का मोबाइल नंबर ',
	ponit_contact_num: 'संपर्क का नंबर ',
	profile_updated_sucess: 'प्रोफाइल को सफलतापूर्वक अपडेट किया गया',
	request_submit_sucess: 'अनुरोध सफलतापूर्वक प्रस्तुत किया गया',
	quote_acpt_success: 'कोट को सफलतापूर्वक स्वीकार किया',
	request_trader_access: 'आपूर्तिकर्ता बनने का अनुरोध',
	requested_supplier_rights: 'आपूर्तिकर्ता पहुंच का अनुरोध किया?',
	received_warehouse_photos: 'वेयरहाउस में प्राप्त तस्वीरों',
	redirecting_in_seconds: '%सेकंड में पुनर्निर्देशन, या',
	success_created_machine: 'सफलतापूर्वक बनाई गई मशीन',
	success_updated_machine: 'सफलतापूर्वक अद्यतन मशीन',
	tc_not_available: 'टीसी उपलब्ध नहीं है',
	tc_success_endorsed: 'टीसी को सफलतापूर्वक एंडोरसेद किया जाता है',
	test_certificate_tc: 'परीक्षण प्रमाणपत्र (टीसी)',
	total_amount_breakup: 'कुल राशि ब्रेकअप',
	total_alloted_weight_mt: 'कुल आवंटित वजन (एमटी)',
	total_items_amount: 'कुल आइटम राशि',
	total_logistics_amount: 'कुल रसद राशि',
	total_order_amount: 'कुल आदेश राशि',
	total_order_weight: 'कुल आदेश वजन',
	total_order_weight_mt: 'ऑर्डर का कुल भार',
	total_serviced_weight: 'कुल सेवित वजन',
	total_serviced_weight_mt: 'कुल सेवित वजन (एमटी)',
	total_serviced_amount: 'कुल सेवा राशि',
	total_quotation_amount: 'कुल कोटेशन राशि',
	order_marked_completed: 'ऑर्डर सफलतापूर्वक पूरा किया गया',
	org_name_required: 'संगठन का नाम आवश्यक है',
	updated_factory_succss: 'कारखाना का सफलतापूर्वक अद्यतन किया गया है',
	upload_more_images: 'अधिक चित्र अपलोड करें',
	utilisation_per_day_mt: 'प्रति दिन उपयोग (एमटी)',
	item_batch_num_exists: 'बैच नंबर %के साथ आइटम पहले से मौजूद है। कृपया नीचे उसी आइटम के लिए विवरण संपादित करें।',
	related_ledgers_deleted: 'संबंधित बहीखाते भी हटा दिए जाएंगे!',
	related_order_ledgers_deleted: 'संबंधित ऑर्डर और बहीखाते भी हटा दिए जाएंगे!',
	update_trip_successfully: 'यात्रा को सफलतापूर्वक अपडेट करें',
	unable_assign_enquiry: 'इन्क्वारी असाइन करने में असमर्थ। पुनः प्रयास करें',
	select_from_existing_addresses: 'या, मौजूदा पते से चयन करें',
	select_input_product_ctgy: 'इनपुट उत्पाद श्रेणियों का चयन करें',
	select_mb_ref_num: 'एमबी संदर्भ संख्या का चयन करें',
	select_output_product_ctgy: 'आउटपुट उत्पाद श्रेणियों का चयन करें',
	sign_you_accept_our: 'साइन इन करके, आप हमारी स्वीकार करते हैं',
	submit_supplier_quote_reason_befor_close: 'कृपया इन्क्वारी बंद करने से पहले आपूर्तिकर्ता उद्धरण कारण सबमिट करें।',
	submit_the_price_ton: 'प्रति टन में कीमतें जमा करें।',
	stop_trip_successfully: 'यात्रा सफलतापूर्वक पूरी हुई',
	want_to_delete_record: 'क्या आप रिकॉर्ड मिटाना चाहते हैं?',
	partner_doesnt_any_factory_yet: 'पार्टनर के पास अभी तक कोई कारखाना नहीं है।',
	provide_balance_sheet_pl: 'क्रेडिट अनुमोदन की संभावनाओं में सुधार करने के लिए कृपया अपनी बैलेंस शीट, पी एंड एल स्टेटमेंट प्रदान करें।',
	provide_consent: 'कृपया अपने आधार विवरण को सत्यापित करने के लिए सहमति प्रदान करें',
	provide_consent_pan: 'कृपया अपने पैन विवरण को सत्यापित करने और प्राप्त करने के लिए सहमति प्रदान करें',
	consent_aadhaar: 'कृपया UIDAI से अपने आधार विवरण को सत्यापित करने और प्राप्त करने के लिए सहमति प्रदान करें',
	provide_details_director: 'कृपया कंपनी के निदेशक का आधार विवरण दर्ज करें',
	pls_enter_admin_msg: 'कृपया व्यवस्थापक संदेश दर्ज करें',
	pls_enter_valid_mobile_num: 'कृपया एक मान्य मोबाइल नंबर दर्ज करें',
	pls_enter_vaild_enquiry_order: 'कृपया बुनियादी विवरणों में एक वैध इन्क्वारी/ऑर्डर आईडी दर्ज करें',
	pls_select_associate_supplier: 'कृपया संबंधित आपूर्तिकर्ता का चयन करें',
	pls_select_non_associate_supplier: 'कृपया गैर संबंधित आपूर्तिकर्ता का चयन करें',
	pls_enter_associate_supplier_msg: 'कृपया संबंधित आपूर्तिकर्ता का संदेश दर्ज करें',
	pls_enter_non_associate_supplier_msg: 'कृपया गैर संबंधित आपूर्तिकर्ता का संदेश दर्ज करें',
	pls_select_factory_start_adding_items: 'कृपया आइटम जोड़ना शुरू करने के लिए एक कारखाने का चयन करें',
	pls_connact_ur_manager_reopen_enquiry: 'कृपया जांच को फिर से खोलने और मेटलबुक से उद्धरण प्राप्त करने के लिए अपने संबंधित प्रबंधक से संपर्क करें।',
	pls_select_partner_first: 'कृपया पहले एक साथी का चयन करें।',
	press_button_approximate_prices: 'अनुमानित कीमतें लाने के लिए बटन दबाएं',
	quotation_has_success_submitted: 'कोटेशन सफलतापूर्वक जमा किया गया ',
	quotation_has_success_updated: 'कोटेशन को सफलतापूर्वक अपडेट किया गया है',
	updated_waste_item: 'अपशिष्ट वस्तु को सफलतापूर्वक अद्यतन किया गया है',
	upload_dispatch_weighment: 'डिस्पैच वेटमेंट अपलोड करें',
	upload_drop_weighment: 'ड्रॉप वेटमेंट अपलोड करें',
	upload_stamp_want_to_use_stamp: 'स्टैम्प अपलोड करें यदि डिफ़ॉल्ट एमबी स्टैम्प का उपयोग नहीं करना है',
	view_basic_order_details: 'बुनियादी ऑर्डर विवरण देखें',
	view_order_summary: 'ऑर्डर सारांश देखें',
	view_purchase_order: 'खरीद ऑर्डर देखें',
	your_email_address: 'आपका ईमेल पता',
	your_full_name: 'आपका पूरा नाम',
	your_org_pan: 'आपके संगठन का पैन',
	view_stock_photo: 'स्टॉक फोटो देखें',
	want_to_delete: 'क्या आप निश्चित रूप से इस आइटम को हटाना चाहते हैं?',
	zoho_contact_ids: 'ज़ोहो संपर्क आईडी',
	click_icon_to_download: 'डाउनलोड करने के लिए आइकन पर क्लिक करें',
	all_utility: 'बिजली/ पानी/ इंटरनेट/ फोन',
	add_atleast_item_create_enquiry: 'इन्क्वारी के लिए कम से कम एक आइटम जोड़ें',
	add_atleast_item_create_order: 'ऑर्डर के लिए कम से कम एक आइटम जोड़ें',
	add_no_pages_in_file: 'फ़ाइल में पृष्ठों की संख्या जोड़ें',
	add_one_item_create_logistics: 'रसद बनाने के लिए कम से कम एक आइटम जोड़ें',
	admin_request_user_kyc: 'क्या आप सुनिश्चित हैं कि आप केवाईसी के लिए उपयोगकर्ता से अनुरोध करना चाहते हैं?',
	admin_request_user_supplier: 'क्या आप वाकई उपयोगकर्ता को सप्लायर के रूप में अनुरोध करना चाहते हैं?',
	average_cost_metric_tone: 'प्रति मीट्रिक टन औसत लागत',
	all_progress_lost_r_you_sure: 'सारी प्रगति खो जाएगी। क्या आप आगे बढ़ना चाहते हैं?',
	click_view_factory_inventory: 'अपनी फ़ैक्टरी इन्वेंट्री देखने के लिए क्लिक करें',
	enter_basic_order_details: 'मूल आदेश विवरण दर्ज करें',
	enter_comma_separated_filed: 'प्रत्येक फ़ील्ड के लिए एकाधिक होने पर अल्पविराम से अलग किए गए मान दर्ज करें',
	enter_9_digit_enquiry: '9 अंकों की इन्क्वारी / ऑर्डर आईडी दर्ज करें',
	enter_valid_contact_num: 'कृपया एक वैध सम्पर्क नम्बर डालें',
	enter_serviced_weight_sku: 'प्रति एसकेयू सेवित वजन दर्ज करें',
	enquiry_has_created_success: 'इन्क्वारी सफलतापूर्वक बनाई गई है',
	fill_req_org_details: 'आवश्यक संगठन विवरण भरें',
	freight_calculator_not_found: 'हमारे पास इन पिनकोड के नवीनतम मूल्य नहीं हैं, कृपया इस मुद्दे के संबंध में अपने संबंधित प्रबंधक से संपर्क करें।',
	invoices_bills_added_successfully: 'इन्वॉइसेस/बिल सफलतापूर्वक जोड़े गए',
	invoice_same_doc_num: 'समान दस्तावेज़ संख्या वाला इन्वॉइस पहले से मौजूद है.',
	max_60_days_allowed: 'अधिकतम 60 दिनों की अनुमति है',
	mbook_will_back_you: 'मेटलबुक आपसे संपर्क करेगा',
	mbook_back_with_best_quotes: 'मेटलबुक आपके पास बेहतरीन कोट के साथ वापस आएगी',
	new_buy_sell_steel: 'स्टील खरीदने और बेचने का नया तरीका',
	upload_distance_xl_sheet: 'दूरी की एक्सेल शीट अपलोड करें',
	err_failed_create_request: 'अनुरोध बनाने में विफल',
	err_failed_update_profile: 'प्रोफ़ाइल अपडेट करने में विफल',
	err_failed_destination_pincode: 'गंतव्य पिनकोड विवरण में विफल',
	err_failed_origin_pincode: 'पिनकोड विवरण मूल करने में विफल',
	err_while_creating_factory_try_again_ltr: 'फ़ैक्टरी बनाते समय त्रुटि। बाद में पुन: प्रयास।',
	err_while_updating_factory_try_again_ltr: 'फ़ैक्टरी अपडेट करते समय त्रुटि। बाद में पुन: प्रयास।',
	err_generate_aadhaar_otp_failed: 'हमें खेद है, UIDAI का सर्वर डाउन है। कृपया कुछ समय बाद पुन: प्रयास करें',
	add_final_supplier_quotes_berfor_place_order: 'ऑर्डर देने से पहले कृपया अंतिम सप्लायर कोट जोड़ें',
	add_relationship_manager_befor_enquiry_active: 'इन्क्वारी सक्रिय करने से पहले कृपया संबंध प्रबंधक जोड़ें',
	all_changes_lost_sure: 'सभी परिवर्तन खो जाएंगे। क्या आपको यकीन है?',
	all_progress_lost_sure: 'सारी प्रगति खो जाएगी। क्या आपको यकीन है?',
	click_here_add_delete_modify_factory_inventory: 'इस फ़ैक्टरी की वस्तु-सूची को जोड़ने, हटाने या संशोधित करने के लिए यहाँ क्लिक करें',
	msg_already_existing_temp:
		'पहले से मौजूद टेम्प्लेट अनुकूलन योग्य हैं और इन्हें सीधे भी इस्तेमाल किया जा सकता है। स्क्रैच से मशीन बनाने के लिए कृपया क्रिएट न्यू पर क्लिक करें।',
	msg_atleast_one_ctgy_attri_must_be_selected: 'कम से कम एक श्रेणी विशेषता का चयन किया जाना चाहिए',
	msg_are_you_sure_want_to_delete_doc: 'क्या आप वाकई इस दस्तावेज़ को हटाना चाहते हैं?',
	msg_are_you_sure_want_to_delete_this_invoice: 'क्या आप वाकई इस इनवॉइस को हटाना चाहते हैं?',
	msg_auction_assigned: 'नीलामी सफलतापूर्वक कर्मचारी(यों) को सौंपी गई',
	msg_auction_unable_assign_auction: 'नीलामी आवंटित करने में असमर्थ। पुनः प्रयास करें',
	msg_auction_created_successfully: 'नीलामी सफलतापूर्वक बनाई गई।',
	msg_auction_failed_to_create: 'नीलामी बनाने में विफल। पुनः प्रयास करें।',
	msg_failed_add_waste_item: 'बेकार वस्तु जोड़ने में विफल। पुनः प्रयास करें',
	msg_failed_create_factory_order: 'फ़ैक्टरी ऑर्डर बनाने में विफल। पुनः प्रयास करें',
	msg_failed_to_create_machine: 'मशीन बनाने में विफल. पुनः प्रयास करें',
	msg_failed_enquiry_order: 'इन्क्वारी/ऑर्डर विवरण प्राप्त करने में विफल',
	msg_failed_partner_factories: 'भागीदार फ़ैक्टरियों को लाने में विफल',
	msg_failed_pincode_details: 'पिनकोड विवरण प्राप्त करने में विफल',
	msg_failed_mark_order_complete: 'आदेश को पूर्ण के रूप में चिह्नित करने में विफल. पुनः प्रयास करें',
	msg_failed_update_factory_order: 'फ़ैक्टरी ऑर्डर अपडेट करने में विफल। पुनः प्रयास करें',
	msg_failed_update_inventory: 'इन्वेंट्री अपडेट करने में विफल',
	msg_failed_to_update_machine: 'मशीन अपडेट करने में विफल. पुनः प्रयास करें',
	msg_failed_update_order_journey: 'ऑर्डर यात्रा को अपडेट करने में विफल। पुनः प्रयास करें',
	msg_auction_failed_to_update: 'नीलामी अपडेट करने में विफल. पुनः प्रयास करें।',
	msg_auction_updated_successfully: 'नीलामी सफलतापूर्वक अपडेट की गई',
	msg_failed_update_waste_item: 'बेकार आइटम अपडेट करने में विफल. पुनः प्रयास करें',
	msg_auction_verified: 'नीलामी सफलतापूर्वक सत्यापित',
	msg_banner_created: 'बैनर सफलतापूर्वक बनाया गया',
	msg_banner_editted: 'बैनर सफलतापूर्वक अपडेट किया गया',
	msg_batch_no_cannot_updated:
		'बैच नंबर अपडेट नहीं किया जा सकता। अगर आप बैच नंबर बदलना चाहते हैं, तो कृपया इस आइटम को हटा दें, और अपडेटेड बैच नंबर के साथ एक नया बनाएं।',
	msg_bid_submitted_successfully: 'बोली सफलतापूर्वक सबमिट की गई।',
	msg_bid_updated_successfully: 'बोली सफलतापूर्वक अपडेट की गई।',
	msg_buyer_invoice_added_successfully: 'क्रेता इनवॉइस सफलतापूर्वक जोड़ा गया',
	msg_buyer_po_uploladed_successfully: 'क्रेता पीओ सफलतापूर्वक अपलोड किया गया',
	msg_convert_to_order: 'ऑर्डर में सफलतापूर्वक कनवर्ट करें',
	msg_click_manage_auction: 'नीलामी का प्रबंधन करने के लिए क्लिक करें',
	msg_enquiry_success: 'जांच सफलतापूर्वक सबमिट की गई',
	msg_wrong_value: 'मान शून्य से कम या उसके बराबर नहीं हो सकता',
	msg_enquiry_assigned: 'कर्मचारी (कर्मचारियों) को सफलतापूर्वक जांच सौंपी गई',
	msg_enquiry_verified: 'पूछताछ सफलतापूर्वक सत्यापित',
	msg_enquiry_cancelled: 'जांच रद्द कर दी गई है',
	msg_failed_to_update_invoice_details: 'इनवॉइस विवरण अपडेट करने में विफल',
	msg_img_upload_failed: 'फोटो अपलोड विफल',
	msg_img_upload_failed_try_again: 'फोटो अपलोड विफल| पुनः प्रयास करें',
	msg_invty_photo_upload_err: 'वस्तु सूची फोटो अपलोड करने में त्रुटि:',
	msg_invoice_details_updated: 'इनवॉइस विवरण अपडेट किया गया',
	msg_item_already_added: 'पूछताछ आइटम पहले ही जोड़ा जा चुका है',
	msg_logistics_success: 'लोजिस्टिक्स सफलतापूर्वक सबमिट किया गया',
	msg_logistics_update: 'लॉजिस्टिक सफलतापूर्वक अपडेट किया गया',
	msg_logistics_price_add: 'लॉजिस्टिक मूल्य सफलतापूर्वक जोड़ा गया',
	msg_logistics_price_update: 'लॉजिस्टिक मूल्य सफलतापूर्वक अपडेट किया गया',
	msg_quotes_submitted: 'उद्धरण सफलतापूर्वक सबमिट किए गए',
	msg_quotes_editted: 'उद्धरण सफलतापूर्वक अपडेट किए गए',
	msg_order_created: 'आदेश सफलतापूर्वक बनाया गया',
	msg_order_created_error: 'आदेश बनाते समय त्रुटि हुई',
	msg_order_editted: 'आदेश सफलतापूर्वक अपडेट किया गया',
	msg_pls_select_atleast_one_brand: 'कृपया कम से कम एक ब्रांड चुनें',
	msg_product_created: 'उत्पाद सफलतापूर्वक बनाया गया',
	msg_product_editted: 'उत्पाद संपादित सफलतापूर्वक',
	msg_brand_created: 'ब्रांड सफलतापूर्वक बनाया गया',
	msg_category_created: 'श्रेणी सफलतापूर्वक बनाई गई',
	msg_category_editted: 'श्रेणी संपादित सफलतापूर्वक',
	msg_select_from_exist_temp_create_new: 'मौजूदा टेम्प्लेट में से चुनें या नया बनाएं',
	msg_select_user: 'कृपया एक उपयोगकर्ता का चयन करें',
	msg_select_supplier: 'कृपया एक आपूर्तिकर्ता का चयन करें',
	msg_success_upload_img: 'तस्वीरें सफलतापूर्वक अपलोड की गई',
	msg_success_upload_tc: 'टीसी सफलतापूर्वक अपलोड किया गया',
	msg_supplier_invoice_added_successfully: 'सप्लायर इनवॉइस सफलतापूर्वक जोड़ा गया',
	msg_supplier_po_uploaded_successfully: 'सप्लायर पीओ सफलतापूर्वक अपलोड किया गया',
	msg_user_created: 'उपयोगकर्ता सफलतापूर्वक बनाया गया',
	msg_user_editted: 'उपयोगकर्ता सफलतापूर्वक अपडेट किया गया',
	msg_transaction_added: 'लेन-देन सफलतापूर्वक जोड़ा गया',
	msg_transaction_amount: 'कृपया लेन-देन राशि दर्ज करें',
	msg_to_buyer: 'खरीदार को संदेश',
	msg_to_associated_suppliers: 'संबद्ध सप्लायरस को संदेश',
	msg_to_non_associated_suppliers: 'गैर संबद्ध सप्लायरस के लिए संदेश',
	msg_user_promoted_trader: 'उपयोगकर्ता को SUPPLIER में सफलतापूर्वक पदोन्नत किया गया',
	msg_enter_supplier_quotes: 'कृपया आपूर्तिकर्ता उद्धरण दर्ज करें।',
	msg_enter_quotes: 'पूछताछ को ऑर्डर में बदलने से पहले कृपया आपूर्तिकर्ता और खरीदार उद्धरणों को अंतिम रूप दें',
	msg_enter_quotes_trader: 'हम अभी भी आपके लिए सर्वश्रेष्ठ उद्धरण प्राप्त कर रहे हैं। संबंधित प्रबंधक से संपर्क करें',
	msg_quote: [
		'कीमतों में जीएसटी को बाहर रखा गया',
		'FOR . के लिए लॉजिस्टिक कोटेशन भरें',
		'आप आंशिक आदेश पर भी बोली लगा सकते हैं',
		'लॉजिस्टिक सपोर्ट न होने की स्थिति में, केवल X कोट सबमिट करें, कोट रैंक नहीं दिखाया जाएगा',
		'प्रबंधक अंतिम वार्ता के लिए शीर्ष 5 उद्धरणों के साथ आपूर्तिकर्ताओं तक पहुंचेगा',
	],
	msg_quote_buyer: [
		'कीमतों में जीएसटी को बाहर रखा गया',
		'कृपया किसी भी प्रश्न के लिए मेटलबुक संबंधित प्रबंधक से संपर्क करें।',
		'ऑर्डर की पुष्टि करने से पहले कृपया दिए गए ब्रांड और वजन की जांच करें।',
	],
	msg_quote_trader: [
		'कीमतों में जीएसटी को बाहर रखा गया',
		'पूर्व गोदाम मूल्य के लिए EX चुनें; रसद समर्थन के मामले में - अपना रसद मूल्य भरें, अन्यथा इसे "शून्य" रखें यदि आपको मेटलबुक से रसद सहायता की आवश्यकता है।,उसी तरह, कृपया सड़क मूल्य पर फ्रेट के लिए FOR चुनें।',
		'आप आंशिक आदेश मात्रा के लिए बोली भी जमा कर सकते हैं',
	],
	msg_service_weight: 'बिल अपलोड करने के बाद कुछ समय में सर्विस्ड वेट अपडेट किया जाएगा',
	msg_available_phase_2: 'सुविधा जल्द ही उपलब्ध होगी',
	msg_user_fill_details: 'आपूर्तिकर्ता बनने के लिए कृपया अपने संगठन का विवरण भरें',
	msg_request_submitted: "'आपूर्तिकर्ता' बनने का आपका अनुरोध दर्ज कर लिया गया है",
	msg_invalid_phone_number: 'अमान्य फ़ोन नंबर, कृपया जांचें और पुनः प्रयास करें।',
	msg_too_many_requests: 'बहुत अधिक लॉगिन अनुरोध हो चुके हैं, कृपया कुछ समय बाद पुन: प्रयास करें।',
	msg_maximum_user_count_exceeded: 'अधिकतम उपयोगकर्ता संख्या तक पहुँच गया। कृपया कुछ समय बाद पुन: प्रयास करें।',
	msg_otp_sent_mobile: 'रजिस्टर्ड मोबाइल पर मैसेज भेज दिया गया है',
	msg_invalid_verification_code: 'अमान्य ओटीपी। कृपया जाँच करें और पुनः प्रयास करें।',
	msg_otp_verified: 'ओटीपी कन्फर्म है।',
	msg_remark_added: 'उपयोगकर्ता टिप्पणी सफलतापूर्वक सबमिट की गई',
	msg_share_any_more_details: 'कोई और जानकारी साझा करें..',
	msg_maximum_file_upload: 'अधिकतम फ़ाइल अपलोड',
	msg_is_in_top_three_quote: 'टॉप थ्री कोट में है।',
	msg_not_in_top_three_quote: 'शीर्ष तीन उद्धरण में नहीं।',
	msg_in_top_3_quote: 'टॉप 3 कोट में?',
	msg_verify_email:
		'आपका ईमेल सत्यापित नहीं है। हमने आपके ईमेल पते पर एक मेल भेजा है। यदि आप मेल का पता नहीं लगा सके तो कृपया अपना स्पैम फ़ोल्डर जांचें।',
	msg_password_reset_mail: 'कृपया पासवर्ड रीसेट करने के लिए ईमेल पर भेजे गए चरणों का पालन करें',
	msg_email_added_successfully: 'ईमेल और पासवर्ड सफलतापूर्वक अपडेट किया गया',
	msg_enter_email_password: 'कृपया ईमेल पता और पासवर्ड दर्ज करें',
	msg_download_started: 'डाउनलोड शुरू हो गया',
	msg_no_special_char: 'विशेष वर्ण जैसे "/ - _ @" आदि न जोड़ें।',
	msg_supplier_quote_successfully_added: 'सप्लायर कोट सफलतापूर्वक जोड़ा गया',
	msg_action_failed_try_again: 'क्रिया: विफल रही है। पुनः प्रयास करें।',
	msg_failed_create_order_try_again: 'ऑर्डर बनाने में विफल. पुनः प्रयास करें',
	msg_quote_accepted: 'कोट स्वीकृत',
	msg_quote_unaccepted: 'कोट अस्वीकार्य',
	msg_failed_accept_quote_try_again: 'कोटेशन स्वीकार करने में विफल. पुनः प्रयास करें',
	msg_failed_unaccept_quote_try_again: 'कोटेशन को अस्वीकार करने में विफल. पुनः प्रयास करें',
	msg_no_enquiry_items_available_try_diff_enquiry: 'कोई इन्क्वारी आइटम उपलब्ध नहीं है, कृपया एक अलग इन्क्वारी/ऑर्डर आईडी का प्रयास करें।',
	msg_update_colse_reason_suppliers_rejection_reson:
		'समापन कारण को अपडेट करने पर, आपूर्तिकर्ताओं को चयनित अस्वीकृति कारण के साथ अधिसूचित किया जाएगा। कृपया समापन कारण सबमिट करते समय सावधान रहें।',
	msg_this_site_protected_recaptcha: 'यह साइट reCAPTCHA और Google द्वारा सुरक्षित है',
	msg_upload_document: 'निम्नलिखित दस्तावेज अपलोड करें',
	msg_video_kyc_completed: 'वीडियो केवाईसी पहले ही पूरा हो चुका है। कृपया बाकी दस्तावेज़ अपलोड करें।',
	msg_video_kyc_text:
		'मैं घोषणा करता हूं कि मेरे द्वारा प्रस्तुत की गई जानकारी मेरी सर्वोत्तम जानकारी के अनुसार सत्य है। \t\t\n मैं वर्तमान में [आवासीय पते] पर रहता हूं, और मैं एक व्यवसाय चला रहा हूं जो एमएस स्क्रैप की बिक्री और खरीद में काम करता है।\t\t\n मेरी कंपनी का पता [जीएसटी पंजीकृत पता] है, यह एक स्व-स्वामित्व / किराये का परिसर है। मैं अपने स्वयं के स्वामित्व वाले / किराये के गोदाम से माल की आपूर्ति का प्रबंधन भी करता हूं, जो कि [मेरा वेयरहाउस पता - व्यापार के अतिरिक्त स्थान के रूप में पंजीकृत होना चाहिए] में स्थित है। \t\t\n मैं सत्यनिष्ठा से घोषणा करता हू, कि मैं एक व्यवसाय चला रहा हूं, जो भारत की भूमि के कराधान नियमों सहित, लेकिन इन तक सीमित नहीं, सभी नियमों और विनियमों के अनुसार है।',
	msg_ifc_calculator: 'IFC की कीमतों की गणना 18% प्रति वर्ष की जाती है',
	placeholder_bid_per_mt: 'प्रति टन बोली',
	placeholder_cost_per_mt: 'लागत प्रति मीट्रिक टन',
	placeholder_enter_batch_no: 'बैच नंबर दर्ज करें',
	placeholder_enter_cast_no: 'कास्ट नंबर दर्ज करें',
	placeholder_enter_comma_separated_refer_no: 'अल्पविराम से अलग एमबी संदर्भ संख्या दर्ज करें',
	placeholder_enter_comma_separated_order_id: 'अल्पविराम से अलग की गई ऑर्डर आईडी दर्ज करें',
	placeholder_enter_factory_name: 'कारखाने का नाम दर्ज करें',
	placeholder_enter_invoice_amount: 'इनवॉइस राशि दर्ज करें',
	placeholder_enter_invoice_date: 'इनवॉइस तिथि दर्ज करें',
	placeholder_enter_invoice_number: 'इनवॉइस संख्या दर्ज करें',
	placeholder_enter_logistics_quotation_per_mt: 'लोजिस्टिक्स कोटेशन प्रति मीट्रिक टन दर्ज करें',
	placeholder_enter_lumpsum_logistics_quotation: 'एकमुश्त लोजिस्टिक्स कोटेशन दर्ज करें',
	placeholder_enter_minimum_order_quantity: 'न्यूनतम आदेश मात्रा दर्ज करें',
	placeholder_enter_quantity: 'मात्रा दर्ज करें',
	placeholder_enter_quote_per_kg: 'भाव/किग्रा दर्ज करें',
	placeholder_enter_serviced_weight_kg: 'सेवित वजन किलो में दर्ज करें',
	plceholder_enter_warehouse_capacity_metric_tonne: 'मीट्रिक टन में गोदाम क्षमता दर्ज करें',
	placeholder_enter_weight_in_mt: 'एमटी में वजन दर्ज करें',
	placeholder_enter_weight_to_be_alloted: 'आवंटित किया जाने वाला वजन दर्ज करें',
	placeholder_enter_value_metric_tonne: 'मीट्रिक टन में मान दर्ज करें',
	placeholder_enter_value_in_kg: 'केजी में मूल्य दर्ज करें',
	placeholder_enter_your_bid_per_mt: 'प्रति मीट्रिक टन अपनी बोली दर्ज करें',
	placeholder_point_contact_name: 'संपर्क बिंदु का नाम',
	placeholder_quote_per_mt: 'बोली प्रति मीट्रिक टन',
	placeholder_select_brand: 'एक ब्रांड का चयन करें',
	placeholder_select_category: 'एक श्रेणी चुनें',
	placeholder_select_supplier: 'सप्लायर का चयन करें',
	placeholder_select_product: 'एक उत्पाद चुनो',
	placeholder_weight_metric_tonne: 'वजन मीट्रिक टन में',
	placeholder_weight_in_mt: 'मीट्रिक टन में वजन',
	provide_one_below_details: 'नीचे दिए गए विवरणों में से कम से कम एक प्रदान करें',
	provides_estimated_value_get_actual_prices:
		'यह सिर्फ अनुमानित मूल्य प्रदान करता है। वास्तविक मूल्य प्राप्त करने के लिए कृपया रसद भागीदार से उद्धरण प्राप्त करें।',
	sanctioned_credit_facility_charged: 'स्वीकृत ऋण सुविधा पर सालाना 18% की दर से शुल्क लिया जाएगा। क्रेडिट केवल वर्तमान आदेश के लिए उपलब्ध है।',
	label_allot_weight: 'आवंटित वजन (एमटी)',
	label_closing_date_minimum_3_days: 'अंतिम तिथि (न्यूनतम 3 दिन)',
	label_metalbook_opening_bid: 'मेटलबुक ओपनिंग बिड / एमटी',
	label_minimum_order_quantity: 'न्यूनतम आदेश मात्रा',
	label_minimum_order_quantity_in_mt: 'एमटी में न्यूनतम आदेश मात्रा (एमओक्यू)।',
	label_opening_bid: 'ओपनिंग बिड / एमटी',
	label_price_valid_till: 'मूल्य तक वैध',
	label_publish_date: 'प्रकाशित तिथि',
	label_logistics_quotation_per_mt: 'लोजिस्टिक्स कोटेशन / मीट्रिक टन',
	label_lumpsum_logistics_quotation: 'एकमुश्त लोजिस्टिक्स कोटेशन',
	label_quote_mt: 'कोट / मीट्रिक टन',
	label_select_from_existing_locations: 'या, मौजूदा स्थानों में से चुनें',
	label_select_validity_date: 'वैधता तिथि का चयन करें',
	label_total_weight_to_be_sold: 'कुल वजन (एमटी) बेचा जाना है',
	label_weight_mt: 'वजन (एमटी)',
	label_weight_mt_per_month: 'वजन (एमटी) प्रति माह',
	label_your_bid_mt: 'आपकी बोली / मीट्रिक टन',
	logistic_ptnr_exists: 'लॉजिस्टिक पार्टनर पहले से मौजूद है',
	logistic_invoice_add_success: 'लॉजिस्टिक इनवॉइस सफलतापूर्वक जोड़ा गया',
	upload_stock_photo_again: 'स्टॉक फोटो फिर से अपलोड करें',
	your_order_name_sitename_po: 'आपका ऑर्डर नाम / साइट का नाम',
	your_organisation_name: 'आपके संगठन का नाम',
	cloud_factory: 'क्लाउड फैक्ट्री',
	factories: 'कारखाने',
	add_new_factory: 'नया कारखाना जोड़ें',
	edit_factory: 'फैक्टरी संपादित करें',
	add_new_machine_template: 'मशीन टेम्पलेट जोड़ें',
	edit_machine_template: 'मशीन टेम्पलेट संपादित करें',
	view_factory_inventory: 'फैक्ट्री इन्वेंटरी देखें',
	error_contact_support: 'समर्थन से संपर्क करें',
	error_something_went_wrong: 'कुछ गलत हो गया। कृपया पुन: प्रयास करें',
	error_email_exists: 'ईमेल पहले से ही मौजूद है। कृपया कोई भिन्न ईमेल पता दर्ज करें',
	error_wrong_password: 'गलत पासवर्ड',
	error_user_found: 'यह ईमेल उपलब्ध नहीं है',
	err_status_not_allowed: 'स्थिति परिवर्तन की अनुमति नहीं है',
	become_a_manufacturer_partner: 'निर्माता भागीदार बनें',
	become_a_supplier_partner: 'सप्लायर पार्टनर बनें',
	become_a_logistics_partner: 'लॉजिस्टिक पार्टनर बनें',
	become_a_proccessing_partner: 'प्रोसेसिंग पार्टनर बनें',
	become_a_scrap_partner: 'स्क्रैप पार्टनर बनें',
	quantity: 'मात्रा',
	batch_number: 'बैच संख्या',
	cast_number: 'कास्ट नंबर',
	uploaded_the_file_successfully: 'फ़ाइल सफलतापूर्वक अपलोड की गई',
	document_upload_failed: 'दस्तावेज़ अपलोड विफल',
	manager: 'प्रबंधक',
	relationship_manager: 'संबंधी प्रबंधक',
	account: 'खाता',
	become_a_manufacturer_partner_description: 'मेटलबुक के साथ मैन्युफैक्चरिंग पार्टनर बनने के लिए यहां क्लिक करें',
	become_a_manufacturer_partner_request_pending: 'मैन्युफैक्चरिंग पार्टनर बनने का आपका अनुरोध मेटलबुक के पास लंबित है। आपको जल्द ही अपडेट मिलेगा।',
	become_a_supplier_partner_description: 'मेटलबुक के साथ सप्लायर पार्टनर बनने के लिए यहां क्लिक करें',
	become_a_supplier_partner_request_pending: 'सप्लायर पार्टनर बनने का आपका अनुरोध मेटलबुक के पास लंबित है',
	become_a_logistics_partner_description: 'मेटलबुक के साथ लॉजिस्टिक पार्टनर बनने के लिए यहां क्लिक करें',
	become_a_logistics_partner_request_pending: 'लॉजिस्टिक पार्टनर बनने का आपका अनुरोध मेटलबुक के पास लंबित है। आपको जल्द ही अपडेट मिलेगा।',
	become_a_proccessing_partner_description: 'मेटलबुक के साथ प्रोसेसिंग पार्टनर बनने के लिए यहां क्लिक करें',
	become_a_scrap_partner_description: 'मेटलबुक के साथ स्क्रैप पार्टनर बनने के लिए यहां क्लिक करें',
	become_a_proccessing_partner_request_pending: 'प्रोसेसिंग पार्टनर बनने का आपका अनुरोध मेटलबुक के पास लंबित है। आपको जल्द ही अपडेट मिलेगा।',
	become_a_scrap_partner_request_pending: 'स्क्रैप पार्टनर बनने का आपका अनुरोध मेटलबुक के पास लंबित है। आपको जल्द ही अपडेट मिलेगा।',
	pls_make_address_r_colum: 'कृपया सुनिश्चित करें कि पते एच और आई कॉलम में हैं',
	request_role_change: 'भूमिका परिवर्तन का अनुरोध करें',
	carousel_get_ahead:
		'मेटलबुक के साथ बाजार के रुझान और कीमतों में उतार-चढ़ाव से आगे बढ़ें और रीयल टाइम अंतर्दृष्टि और बाजार समाचार प्राप्त करें। आपको सर्वोत्तम संभव मूल्य और सौदे प्राप्त करने के लिए भारत भर के सभी प्रमुख निर्माताओं और आपूर्तिकर्ताओं के साथ भागीदारी।',
	carousel_guaranteed_time_delivery:
		'मेटलबुक के साथ समय में डिलीवरी की गारंटी, रीयल-टाइम ट्रैकिंग सुविधा ताकि आपको अपनी डिलीवरी समयसीमा के बारे में चिंता न करनी पड़े।',
	carousel_get_access:
		'निर्बाध क्रेडिट लाइन सुविधा तक पहुंच प्राप्त करें और अपने व्यवसाय को अनुकूलित करने के लिए हमारे साथ सुविधाजनक भुगतान विकल्पों का पता लगाएं।',
	carousel_helping_buyers:
		'खरीदारों को उनकी आवश्यकताओं पर तत्काल कोट प्राप्त करने में सहायता करना और उन्हें हमारे वेरीफाइड सप्लायरस के माध्यम से पूरा करना',
	carousel_see_quotations:
		'सभी कोटेशन देखें, कीमतों की तुलना करें, अपने सभी चालानों, अन्य संबंधित दस्तावेज़ों, खाता बही विवरणों को प्रबंधित करें और वास्तविक समय में अपने शिपमेंट को ट्रैक करें। खरीद आदेश और बिल आसानी से ऑनलाइन बनाएं।',
	carousel_source_through: 'एकाधिक सत्यापित निर्माताओं के माध्यम से स्रोत, जिन्हे इन-हाउस लॉजिस्टिक्स के माध्यम  से पूरा किया जाता है',
	carousel_verified_network:
		'पूरे भारत में 1000 + सप्लायरस का सत्यापित नेटवर्क जिससे आपको सभी आवश्यक सामग्रियों का सर्वोत्तम मूल्य, गुणवत्ता और आसानी से उपलब्ध स्टॉक प्राप्त हो',
	carousel_view_product: 'उत्पाद कैटलॉग देखें, आरएफक्यू उत्पन्न करें, डिजिटल रूप से कई कोटेशन की तुलना करें।',
	supply_scrap: 'स्क्रैप आपूर्ति',
	msg_failed_to_fetch_scrap_requirements: 'स्क्रैप आवश्यकताओं को लाने में विफल। कृपया पृष्ठ को फिर से लोड करने का प्रयास करें।',
	msg_scrap_quotation_submitted_successfully: 'स्क्रैप मूल्य उद्धरण सफलतापूर्वक जमा किया गया।',
	msg_scrap_quotation_submitted_failed: 'स्क्रैप मूल्य उद्धरण जमा करने में विफल। फिर से प्रयास करें।',
	demand_at: 'मांग पर',
	per_month: '/ महीना',
	type_of_scrap: 'स्क्रैप का प्रकार',
	your_quote: 'आपका मूल्य उद्धरण',
	your_monthly_receivables: 'आपकी मासिक रसीदें',
	proceed: 'आगे बढ़ें',
	where_can_you_supply: 'आप कहां आपूर्ति कर सकते हैं?',
	quote_now: 'अभी मूल्य उद्धरण दें',
	select_available_scrap_category: 'एक और स्क्रैप प्रकार जोड़ें',
	orgs_and_users: 'संगठन और उपयोगकर्ता',
}
export default hindi
