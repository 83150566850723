import { convertTimeLabel, getTimeLabel } from '@utils'

export const processData = (data, meta, period = 6) => {
	let labels = new Set()

	data.forEach((_d) => {
		_d.data.forEach((_item) => {
			labels.add(getTimeLabel(_item.month, _item.year))
		})
	})

	labels = Array.from(labels)
		.sort((a, b) => convertTimeLabel(a) - convertTimeLabel(b))
		.splice(-1)

	if (labels.length === 0) {
		labels.push(getTimeLabel(new Date().getMonth() + 1, new Date().getFullYear()))
	}

	const _dummyLabels = []
	for (let i = 0; i < period - 1; i++) {
		const newDate = convertTimeLabel(labels[0])
		newDate.setMonth(newDate.getMonth() - (period - 1 - i))
		_dummyLabels.push(getTimeLabel(newDate.getMonth() + 1, newDate.getFullYear()))
	}

	labels = [..._dummyLabels, ...labels]

	let accumulatedData = {}

	const finalData = data.reduce((partialData, stats) => {
		return labels.reduce((partialStatData, label) => {
			const curDate = convertTimeLabel(label)
			const _labelStat = stats.data.find((x) => x.month === curDate.getMonth() + 1 && x.year === curDate.getFullYear())
			const _currLabelStat = partialData[label]
			let _value = 0
			if (_labelStat && _labelStat[meta.dataKey]) {
				_value = _labelStat[meta.dataKey] / (meta.valueDivisor ?? 1)

				accumulatedData = {
					...accumulatedData,
					[stats.key]: {
						unit: (_labelStat?.unit ?? 0) + (accumulatedData?.[stats.key]?.unit ?? 0),
						amount: (_labelStat?.amount ?? 0) + (accumulatedData?.[stats.key]?.amount ?? 0),
						weight: (_labelStat?.weight ?? 0) + (accumulatedData?.[stats.key]?.weight ?? 0),
					},
				}
			}
			return {
				...partialStatData,
				[label]: {
					..._currLabelStat,
					[stats.key]: _value,
				},
			}
		}, partialData)
	}, {})

	const dataKeys = data.reduce(
		(acc, _d, index) => ({
			...acc,
			[`yKey${index}`]: _d.key,
		}),
		{}
	)

	return {
		data: Object.entries(finalData).map(([key, val]) => ({ ...val, label: key })),
		xKey: 'label',
		numberOfItems: data?.length,
		accumulatedData,
		...dataKeys,
		...meta,
	}
}
export const processDataForPieChart = ({ data }, meta, period = 6) => {
	if (!Array.isArray(data))
		return {
			data: [],
			numberOfItems: 0,
			...meta,
		}

	const totalDataKeyValue = data.reduce((acc, _d) => acc + _d[meta.dataKey], 0)

	const _data = data.reduce((acc, _d) => {
		const percent = _d[meta.dataKey] / totalDataKeyValue

		if (percent < 0.02) {
			const othersItemIndex = acc.findIndex((_item) => _item.isOthers)
			if (othersItemIndex !== -1) {
				const othersItem = acc[othersItemIndex]
				const dataKeyValue = _d[meta.dataKey] + othersItem[meta.dataKey]
				acc[othersItemIndex] = {
					...othersItem,
					categories: [
						...othersItem.categories,
						{
							..._d,
							percent,
							category_name: _d.category_name.split(',')[0],
						},
					],
					[meta.dataKey]: dataKeyValue,
					percent: percent + othersItem.percent,
					labelKey: meta.labelKey,
					formatted_value: typeof meta.formatter === 'function' ? meta.formatter(dataKeyValue) : dataKeyValue,
				}
			} else {
				acc.push({
					isOthers: true,
					category_name: 'Other',
					categories: [
						{
							..._d,
							percent,
							category_name: _d.category_name.split(',')[0],
						},
					],
					[meta.dataKey]: _d[meta.dataKey],
					percent,
					labelKey: meta.labelKey,
					formatted_value: typeof meta.formatter === 'function' ? meta.formatter(_d[meta.dataKey]) : _d[meta.dataKey],
				})
			}
		} else {
			acc.push({
				..._d,
				percent,
				category_name: _d.category_name.split(',')[0],
				labelKey: meta.labelKey,
				formatted_value: typeof meta.formatter === 'function' ? meta.formatter(_d[meta.dataKey]) : _d[meta.dataKey],
			})
		}

		return acc
	}, [])

	return {
		data: _data,
		numberOfItems: _data?.length,
		...meta,
	}
}
