const firebaseConfig = {
	apiKey: 'AIzaSyCo5E1ERv5pXDt1Wa2eqn_EVBM4Ew-6EEQ',
	authDomain: 'metalbook-79304.firebaseapp.com',
	projectId: 'metalbook-79304',
	storageBucket: 'metalbook-79304.appspot.com',
	messagingSenderId: '978354637985',
	appId: '1:978354637985:web:d4b8d07828b256deb6b4e0',
	measurementId: 'G-7VY2KHMS8L',
}

export const vapidKey = 'BPbbn1yvmAiXrtvFRKRQd7ZcmKpd5YGhVL8cwD_haU5sLhV7IU8pAuep1pc06SxbVGBC0hK9wdfrtvo_UoxDHB8'

export default firebaseConfig
