import Cookies from 'universal-cookie'
import English from './english'
import Hindi from './hindi'
import Bengali from './bengali'
import { cookieKeys, languages } from '@constants'

const cookies = new Cookies()

const strings = (k, k2 = '', separator = ' ') => {
	if (typeof k === 'object' && k.length > 0) {
		const values = []
		k.map((item) => {
			values.push(getString(item))
			return null
		})
		return values.join(separator)
	}

	const key = k.toLowerCase()

	if (typeof k2 === 'object' && k2.length > 0 && typeof k === 'string') {
		// dynamic values with %s as dynamic value
		let rStr = getString(key)
		k2.forEach((value) => {
			rStr = rStr.replace('%s', value)
		})
		return rStr
	}

	const key2 = k2.toLowerCase()

	if (key2 === '') {
		return getString(key)
	}

	return [getString(key), getString(key2)].join(separator)
}

const getString = (key) => {
	const lang = cookies.get(cookieKeys.language)

	switch (lang) {
		case languages.hindi:
			if (Hindi.hasOwnProperty(key)) {
				return Hindi[key]
			}
		case languages.bengali:
			if (Bengali.hasOwnProperty(key)) {
				return Bengali[key]
			}
		default:
			if (English.hasOwnProperty(key)) {
				return English[key]
			} else {
				return key
			}
	}
}

export default strings
