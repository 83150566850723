const showDateFormat = (nowDate = new Date(), yearFirst = false) => {
	if (yearFirst) {
		return nowDate.getFullYear() + '-' + ('0' + (nowDate.getMonth() + 1)).substr(-2) + '-' + ('0' + nowDate.getDate()).substr(-2)
	}
	return ('0' + nowDate.getDate()).substr(-2) + '-' + ('0' + (nowDate.getMonth() + 1)).substr(-2) + '-' + nowDate.getFullYear()
}

const convertDate = (v, isEndOfDay = true) => {
	return (
		new Date(
			v.substring(6, 10),
			v.substring(3, 5) - 1,
			v.substring(0, 2),
			isEndOfDay ? 23 : 0,
			isEndOfDay ? 59 : 0,
			isEndOfDay ? 59 : 0
		).getTime() + Math.floor(Math.random() * 999)
	)
}

export const convertLongDate = (date, endOfDay, isSearch) => {
	const _d = new Date(date)
	_d.setHours(endOfDay ? 23 : 0)
	_d.setMinutes(endOfDay ? 59 : 0)
	_d.setSeconds(endOfDay ? 59 : 0)
	if (isSearch) {
		_d.setMilliseconds(endOfDay ? 999 : 1)
	} else {
		_d.setMilliseconds(Math.floor(Math.random() * 999))
	}
	return _d
}

export const getTodayDate = (endOfDay) => {
	const _d = new Date()
	_d.setHours(endOfDay ? 23 : 0)
	_d.setMinutes(endOfDay ? 59 : 0)
	_d.setSeconds(endOfDay ? 59 : 0)
	return _d
}


// const updateEndOfDay = (v, isEndOfDay = true) => {
// 	return (
// 		new Date(
// 			v.substring(6, 10),
// 			v.substring(3, 5) - 1,
// 			v.substring(0, 2),
// 			isEndOfDay ? 23 : 0,
// 			isEndOfDay ? 59 : 0,
// 			isEndOfDay ? 59 : 0
// 		).getTime() + Math.floor(Math.random() * 999)
// 	)
// }

const monthDiff = (d1, d2) => {
	let _months
	_months = (d2.getFullYear() - d1.getFullYear()) * 12
	_months += d2.getMonth() - d1.getMonth()
	return _months
}

const getTimeLabel = (month, year) => {
	let valDate = new Date(year, month - 1, 1)
	valDate = valDate.toLocaleString('default', { month: 'short' }) + ' ' + valDate.getFullYear().toString().slice(-2)
	return valDate
}

const months = {
	Jan: '0',
	Feb: '1',
	Mar: '2',
	Apr: '3',
	May: '4',
	Jun: '5',
	Jul: '6',
	Aug: '7',
	Sep: '8',
	Oct: '9',
	Nov: '10',
	Dec: '11',
}

const convertTimeLabel = (dateStr) => {
	let valDate = new Date()
	valDate.setDate(1)
	valDate.setMonth(months[dateStr.substr(0, 3)])
	valDate.setFullYear('20' + dateStr.substr(-2))
	return valDate
}

export { showDateFormat, convertDate, monthDiff, getTimeLabel, convertTimeLabel }
