const bengali = {
	metalbook: 'মেটালবুক',
	company: 'মেটালবুক',
	admin: 'অ্যাডমিন',
	trade: 'বাণিজ্য',

	all: 'সব',
	action: 'কর্ম',
	active: 'সক্রিয়',
	add: 'যোগ করুন',
	address: 'ঠিকানা',
	allotted: 'বরাদ্দ',
	amount: 'পরিমাণ',
	analytics: 'বিশ্লেষণ',
	assign: 'বরাদ্দ',
	attributes: 'গুণাবলী',
	auction: 'নিলাম',
	average: 'গড়',
	back: 'পেছনে',
	balance: 'ব্যালেন্স',
	bills: 'বিল',
	bilti: 'লরি রসিদ',
	brand: 'ব্র্যান্ড',
	buyer: 'ক্রেতা',
	business: 'ব্যবসা',
	by: 'দ্বারা',
	capacity: 'ক্ষমতা',
	cancelled: 'বাতিল',
	catalog: 'ক্যাটালগ',
	category: 'বিভাগ',
	certificate: 'সনদপত্র',
	city: 'শহর',
	closed: 'বন্ধ',
	complete: 'সম্পূর্ণ',
	completed: 'সমাপ্ত',
	create: 'সৃষ্টি',
	created: 'সৃষ্টি হয়েছে',
	credit: 'ক্রেডিট',
	dashboard: 'ড্যাশবোর্ড',
	date: 'তারিখ',
	debit: 'ডেবিট',
	delete: 'মুছে ফেলা',
	deposit: 'আমানত',
	description: 'বর্ণনা',
	details: 'বিস্তারিত',
	document: 'ডকুমেন্ট',
	documents: 'নথিপত্র',
	download: 'ডাউনলোড',
	edit: 'সম্পাদনা',
	email_id: 'ইমেইল আইডি',
	employee: 'কর্মচারী',
	enquiry: 'অনুসন্ধান',
	enquiries: 'অনুসন্ধান',
	entry: 'প্রবেশ',
	expiry: 'মেয়াদ শেষ',
	excluding: 'বাতিল',
	ewaybill: 'ই-ওয়ে বিল',
	final: 'ফাইনাল',
	for: 'জন্য',
	given: 'প্রদত্ত',
	gst: 'জিএসটি',
	id: 'আইডি',
	including: 'সহ',
	invoice: 'চালান',
	invoices: 'চালান',
	language: 'ভাষা',
	ledger: 'লেজার',
	ledgers: 'লেজার',
	list: 'তালিকা',
	logistics: 'লজিস্টিক',
	logout: 'প্রস্থান',
	lumpsum: 'একটি একক সমষ্টিগত অর্থ',
	material: 'উপাদান',
	message: 'বার্তা',
	mgw: 'MGW',
	mgw_full: 'ন্যূনতম গ্যারান্টিযুক্ত ওজন',
	mobile: 'মুঠোফোন',
	monthly: 'মাসিক',
	mt: 'MT',
	my: 'আমার',
	name: 'নাম',
	no: 'না',
	number: 'সংখ্যা',
	open: 'খোলা',
	order: 'অর্ডার',
	organisation: 'সংগঠন',
	other: 'অন্য',
	otp: 'OTP',
	pan: 'প্যান',
	particulars: 'বিশেষ',
	phone: 'ফোন',
	pincode: 'পিনকোড',
	po: 'PO',
	product: 'পণ্য',
	profile: 'প্রোফাইল',
	purchase: 'ক্রয়',
	quote: 'উদ্ধৃতি',
	quotes: 'উদ্ধৃতি',
	remarks: 'মন্তব্য',
	rfq: 'আরএফকিউ',
	role: 'ভূমিকা',
	sale: 'বিক্রয়',
	search: 'অনুসন্ধান',
	select: 'নির্বাচন করুন',
	source: 'সূত্র',
	state: 'রাষ্ট্র',
	status: 'স্থিতি',
	submit: 'জমা দিন',
	submitted: 'জমা',
	supplier: 'সরবরাহকারী',
	test: 'পরীক্ষা',
	trader: 'ব্যবসায়ী',
	transaction: 'লেনদেন',
	total: 'মোট',
	type: 'টাইপ',
	unaccept: 'অগ্রহণযোগ্য',
	units: 'ইউনিট',
	update: 'হালনাগাদ',
	upload: 'আপলোড',
	uploaded: 'আপলোড',
	user: 'ব্যবহারকারী',
	users: 'ব্যবহারকারী',
	verify: 'যাচাই করুন',
	view: 'দেখুন',
	weight: 'ওজন',
	winning: 'জয়',
	x: 'প্রাক্তন',
	your: 'তোমার',

	active_auction: 'সক্রিয় নিলাম',
	add_new_item: 'নতুন আইটেম যোগ করুন',
	auction_id: 'নিলাম আইডি',
	add_new_quote: 'নতুন উদ্ধৃতি যোগ করুন',
	all_auction: 'সমস্ত নিলাম',
	allotted_weight: 'বরাদ্দ ওজন',
	apply_credit: 'ক্রেডিট জন্য আবেদন করুন',
	assign_auction: 'নিলাম বরাদ্দ',
	back_orders: 'ব্যাক টু অর্ডার',
	bid_auction: 'বস্তুর উপর বিড',
	bills_generated: 'বিল জেনারেটেড',
	category_name: 'বিভাগ নাম',
	category_id: 'ক্যাটাগরি আইডি',
	company_name: 'কোমপানির নাম',
	concerned_manager: 'সংশ্লিষ্ট ম্যানেজার',
	converted_order: 'অর্ডারে রূপান্তরিত',
	create_auction: 'নিলাম তৈরি করুন',
	create_enquiry: 'তদন্ত তৈরি করুন',
	credit_book: 'ক্রেডিট বুক',
	credit_discount: 'ক্রেডিট (ছাড়)',
	delivery_location: 'ডেলিভারির ঠিকানা',
	dharamkata_slip: 'ওজন স্লিপ',
	dispatch_location: 'ডিসপ্যাচ লোকেশন',
	my_profile: 'আমার প্রোফাইল',
	enquiry_id: 'তদন্ত আইডি',
	expiry_date: 'মেয়াদ শেষ হওয়ার তারিখ',
	finalize_quote: 'উদ্ধৃতি চূড়ান্ত করুন',
	fullfill_enquiry: 'তদন্ত পূরণ করুন',
	gst_18: 'GST (18%)',
	hsn_code: 'HSN কোড',
	invoice_no: 'চালান নং',
	is_top: 'আমি থামলাম?',
	list_inventory: 'তালিকা জায়',
	logistics_charges: 'লজিস্টিক চার্জ',
	logistics_requests: 'লজিস্টিক অনুরোধ',
	manage_auctions: 'নিলাম পরিচালনা করুন',
	manage_enquiries: 'অনুসন্ধানগুলি পরিচালনা করুন',
	manage_orders: 'অর্ডার পরিচালনা করুন',
	manage_enquiry_orders: 'তদন্ত আদেশ পরিচালনা করুন',
	manage_auction_orders: 'নিলামের আদেশ পরিচালনা করুন',
	manage_purchase_orders: 'পারচেজ অর্ডার ম্যানেজ করুন',
	manage_supply_orders: 'সাপ্লাই অর্ডার ম্যানেজ করুন',
	my_auctions: 'আমার নিলাম',
	my_enquiries: 'আমার জিজ্ঞাসা',
	open_auction: 'ওপেন অকশন',
	open_enquiries: 'ওপেন ইনকোয়ারিজ',
	opening_price: 'খোলার দাম',
	ordered_weight: 'অর্ডার করা ওজন',
	payment_summary: 'পারিশ্রমিক সারসংক্ষেপ',
	price_validity: 'মূল্য বৈধতা',
	promote_trader: 'সাপ্লাইয়ারে উন্নীত করুন',
	product_name: 'পণ্য',
	product_id: 'পণ্য আইডি',
	purchase_invoices: 'ক্রয় চালান',
	quote_per_kg: 'কোট প্রতি কেজি',
	quoted_amount_x: 'উদ্ধৃত পরিমাণ (EX)',
	quote_received: 'উদ্ধৃতি প্রাপ্ত',
	quotes_submitted: 'উদ্ধৃতি জমা দেওয়া হয়েছে',
	rate_validity: 'রেট বৈধতা',
	reactivate: 'পুনরায় সক্রিয় করুন',
	request_logistics: 'রিকোয়েস্ট লজিস্টিকস',
	required_weight: 'প্রয়োজনীয় ওজন',
	running_order_book: 'রানিং অর্ডার বুক',
	sale_invoices: 'সেল ইনভয়েস',
	select_date: 'তারিখ নির্বাচন করুন',
	servicable_weight: 'সার্ভিকেবল ওয়েট',
	serviced_weight: 'পরিষেধিত ওজন',
	shipping_location: 'শিপিং লোকেশন',
	supplier_by_inventory: 'সাপ্লাইয়ার ইনভেন্টরি',
	tc_reqd: 'টিসি লাগবে?',
	tc_avl: 'টিসি পাওয়া যায়?',
	thumbnail_url: 'থাম্বনেল ইউআরএল',
	total_amount: 'সর্বমোট পরিমাণ',
	total_amount_for: 'মোট পরিমাণ (ফর)',
	total_items: 'মোট আইটেম',
	total_weight: 'সম্পূর্ণ ওজন',
	transaction_type: 'লেনদেন প্রকার',
	upcoming_auction: 'আসন্ন নিলাম',
	verify_auction: 'নিলাম যাচাই করুন',
	your_bids: 'আপনার বিডস',
	weight_mt: 'ওজন (MT)',
	convert_to_order: 'ORDER এ রূপান্তর করুন',
	enquiry_open_till: 'তদন্ত খোলা পর্যন্ত',
	have_tc: 'আপনার কাছে কি এই পণ্যের জন্য টেস্ট সার্টিফিকেট আছে?',
	is_tc_reqd: 'টেস্ট সার্টিফিকেট কি প্রয়োজন?',
	is_final_quote: 'এটাই কি চূড়ান্ত উদ্ধৃতি?',
	logistics_quotation_mt: 'লজিস্টিক কোটেশন/MT',
	monthly_conversion_chart: 'মাসিক তদন্ত -> অর্ডার রূপান্তর',
	monthly_quotes_conversion_chart: 'মাসিক উদ্ধৃতি -> অর্ডার রূপান্তর',
	monthly_sale_purchase_chart: 'মাসিক বিক্রয়/ক্রয় তুলনা',
	moq: 'MOQ (ন্যূনতম অর্ডারের পরিমাণ)',
	request_trader_access: 'সাপ্লাইয়ার হওয়ার অনুরোধ',
	requested_supplier_rights: 'সরবরাহকারী অ্যাক্সেসের অনুরোধ করেছেন?',
	total_order_amount: 'টোটাল অর্ডারের পরিমাণ',
	total_order_weight: 'টোটাল অর্ডার ওয়েট',
	total_serviced_weight: 'টোটাল সার্ভিসড ওয়েট',
	total_serviced_amount: 'টোটাল সার্ভিসড অ্যামাউন্ট',
	total_quotation_amount: 'মোট উদ্ধৃতি পরিমাণ',
	msg_auction_assigned: 'নিলাম সফলভাবে কর্মচারী(দের) কে বরাদ্দ করা হয়েছে',
	msg_enquiry_success: 'তদন্ত সফলভাবে জমা দেওয়া হয়েছে',
	msg_wrong_value: 'মান শূন্যের কম বা সমান হতে পারে না',
	msg_enquiry_assigned: 'অনুসন্ধান সফলভাবে কর্মচারী(দের) কে বরাদ্দ করা হয়েছে',
	msg_enquiry_verified: 'তদন্ত সফলভাবে যাচাই করা হয়েছে',
	msg_enquiry_cancelled: 'তদন্ত বাতিল করা হয়েছে',
	msg_item_already_added: 'তদন্ত আইটেম ইতিমধ্যে যোগ করা হয়েছে',
	msg_quotes_submitted: 'উদ্ধৃতি সফলভাবে জমা দেওয়া হয়েছে',
	msg_quotes_editted: 'উদ্ধৃতি সফলভাবে আপডেট করা হয়েছে',
	msg_order_created: 'অর্ডার সফলভাবে তৈরি',
	msg_order_created_error: 'অর্ডার তৈরি করার সময় ত্রুটি ঘটেছে',
	msg_order_editted: 'অর্ডার সফলভাবে আপডেট হয়েছে',
	msg_product_created: 'পণ্য সফলভাবে তৈরি',
	msg_product_editted: 'পণ্য সম্পাদনা সফলভাবে',
	msg_category_created: 'বিভাগ সফলভাবে তৈরি হয়েছে',
	msg_category_editted: 'বিভাগ সম্পাদনা সফলভাবে',
	msg_select_user: 'অনুগ্রহ করে একজন ব্যবহারকারী নির্বাচন করুন',
	msg_select_supplier: 'একজন সরবরাহকারী নির্বাচন করুন',
	msg_user_created: 'ব্যবহারকারী সফলভাবে তৈরি',
	msg_user_editted: 'ব্যবহারকারী সফলভাবে আপডেট হয়েছে',
	msg_transaction_added: 'লেনদেন সফলভাবে যোগ করা হয়েছে',
	msg_user_promoted_trader: 'ব্যবহারকারীকে সফলভাবে সরবরাহকারীতে উন্নীত করা হয়েছে',
	msg_enter_supplier_quotes: 'দয়া করে সরবরাহকারীর উদ্ধৃতি লিখুন।',
	msg_enter_quotes: 'অর্ডারে তদন্ত রূপান্তর করার আগে অনুগ্রহ করে সরবরাহকারী এবং ক্রেতার উদ্ধৃতি চূড়ান্ত করুন',
	msg_enter_quotes_trader: 'আমরা এখনও আপনার জন্য সেরা উদ্ধৃতি পাচ্ছি। সংশ্লিষ্ট পরিচালকের সাথে যোগাযোগ করুন',

	msg_quote: [
		'দামের মধ্যে GST বাদ দেওয়া হয়েছে',
		'এর জন্য লজিস্টিক কোটেশন পূরণ করুন',
		'আপনি আংশিক অর্ডারেও বিড করতে পারেন',
		'কোনও লজিস্টিক সাপোর্ট না থাকলে, শুধুমাত্র X কোট জমা দিন, কোট র‌্যাঙ্ক দেখানো হবে না',
		'পরিচালক চূড়ান্ত আলোচনার জন্য শীর্ষ 5 উদ্ধৃতি সহ সরবরাহকারীদের কাছে পৌঁছাবেন',
	],
	msg_quote_buyer: [
		'দামের মধ্যে জিএসটি বাদ।',
		'যেকোনো প্রশ্নের জন্য মেটালবুক সংশ্লিষ্ট ম্যানেজারের সাথে যোগাযোগ করুন।',
		'অর্ডার নিশ্চিত করার আগে অফার করা ব্র্যান্ড এবং ওজন চেক করুন।',
	],
	msg_quote_trader: [
		'দামে জিএসটি বাদ।',
		`প্রাক্তন গোডাউন মূল্যের জন্য EX চয়ন করুন; লজিস্টিক সাপোর্টের ক্ষেত্রে - আপনার লজিস্টিক মূল্য পূরণ করুন, অন্যথায় মেটালবুক থেকে লজিস্টিক সাপোর্টের প্রয়োজন হলে 'জিরো' রাখুন।`,
		'একইভাবে, অনুগ্রহ করে FOR, মালবাহী রাস্তার মূল্যের জন্য চয়ন করুন৷',
		'আপনি আংশিক অর্ডার পরিমাণ জন্য উদ্ধৃতি জমা দিতে পারেন',
	],
	msg_service_weight: 'বিল আপলোড করার পরে কিছু সময়ের মধ্যে সার্ভিসড ওজন আপডেট করা হবে',
	msg_available_phase_2: 'বৈশিষ্ট্য শীঘ্রই উপলব্ধ করা হবে',
	msg_user_fill_details: 'সরবরাহকারী হওয়ার জন্য অনুগ্রহ করে আপনার প্রতিষ্ঠানের বিবরণ পূরণ করুন',
	msg_request_submitted: "'সাপ্লাইয়ার' হওয়ার জন্য আপনার অনুরোধ রেকর্ড করা হয়েছে",

	error_contact_support: 'যোগাযোগ সমর্থন',
	msg_share_any_more_details: 'আরো বিস্তারিত শেয়ার করুন..',
	total_sales: 'মোট বিক্রয়',
	sale_order: 'বিক্রয় আদেশ',
	business_purchase: 'ব্যবসা ক্রয়',
	purchase_order: 'ক্রয় আদেশ',
	price_per_kg: 'প্রতি কেজি দাম',
	total_items_amount: 'মোট আইটেম পরিমাণ',
	total_logistics_amount: 'মোট লজিস্টিক পরিমাণ',
	total_gst: 'মোট জিএসটি',
	GST_18: 'জিএসটি (18%)',
	order_summary: 'অর্ডার সারাংশ',
	msg_maximum_file_upload: 'সর্বোচ্চ ফাইল আপলোড',

	supply_scrap: 'স্ক্র্যাপ সরবরাহ করুন',
	msg_failed_to_fetch_scrap_requirements: 'স্ক্র্যাপ প্রয়োজনীয়তা নিয়ে তথ্য পাওয়া যায়নি। দয়া করে পৃষ্ঠাটি পুনরায় লোড করার চেষ্টা করুন।',
	msg_scrap_quotation_submitted_successfully: 'স্ক্র্যাপ উদ্ধৃতি সফলভাবে জমা দেওয়া হয়েছে।',
	msg_scrap_quotation_submitted_failed: 'স্ক্র্যাপ উদ্ধৃতি জমা দেওয়া ব্যর্থ হয়েছে। আবার চেষ্টা করুন।',
	demand_at: 'চাহিদা',
	per_month: '/ মাস',
	type_of_scrap: 'স্ক্র্যাপের ধরণ',
	your_quote: 'আপনার উদ্ধৃতি',
	your_monthly_receivables: 'আপনার মাসিক গ্রহণযোগ্য',
	proceed: 'এগিয়ে যান',
	where_can_you_supply: 'আপনি কোথায় সরবরাহ করতে পারেন?',
	quote_now: 'এখনই উদ্ধৃতি জমা দিন',
	select_available_scrap_category: 'একটি আরও স্ক্র্যাপ ধরণ যুক্ত করুন',
}

export default bengali
