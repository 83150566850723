export const searchFilter = {
	ENQUIRY_SEARCH: 'ENQUIRY_SEARCH',
	ASSIGN_ENQUIRY_SEARCH: 'ASSIGN_ENQUIRY_SEARCH',
	ENQUIRY_ORDER_SEARCH: 'ENQUIRY_ORDER_SEARCH',
	ACTIVE_INVOICE_SEARCH: 'ACTIVE_INVOICE_SEARCH',
	AUCTION_ORDER_SEARCH: 'AUCTION_ORDER_SEARCH',
	AUCTION_SEARCH: 'AUCTION_SEARCH',
	ASSIGN_AUCTION_SEARCH: 'ASSIGN_AUCTION_SEARCH',
	PRODUCT_SEARCH: 'PRODUCT_SEARCH',
	USERS_SEARCH: 'USERS_SEARCH',
	BUYER_ENQUIRY_SEARCH: 'BUYER_ENQUIRY_SEARCH',
	BUYER_AUCTION_SEARCH: 'BUYER_AUCTION_SEARCH',
	BUYER_ENQUIRY_ORDER_SEARCH: 'BUYER_ENQUIRY_ORDER_SEARCH',
	BUYER_LOGISTICS_SEARCH: 'BUYER_LOGISTICS_SEARCH',
	CLOUD_FACTORY_SEARCH: 'CLOUD_FACTORY_SEARCH',
	FACTORY_MANAGE_ORDER_SEARCH: 'FACTORY_MANAGE_ORDER_SEARCH',
	COMMODITY_PRICE_SEARCH: 'COMMODITY_PRICE_SEARCH',
	INVOICE_SEARCH: 'INVOICE_SEARCH',
	UNDERWRITING_SEARCH: 'UNDERWRITING_SEARCH',
	ADMIN_PURCHASE_FILTER: '_PURCHASE_FILTER',
	ADMIN_ORG_LEDGER_FILTER: 'ORG_LEDGER_FILTER',
	SALES_PURCHASE_FILTER: 'SALES_PURCHASE_FILTER',
	BUYER_PURCHASE_FILTER: 'BUYER_PURCHASE_FILTER',
	PAST_REPORTS: 'PAST_REPORTS',
	ADMIN_BANNER_SEARCH: 'ADMIN_BANNER_SEARCH',
	ORG_SEARCH: 'ORG_SEARCH',
	COLLECTION_FILTER: 'COLLECTION_FILTER',
	SALES_COLLECTION_FILTER: 'SALES_COLLECTION_FILTER',
	ADMIN_DASHBOARD_SEARCH: 'ADMIN_DASHBOARD_SEARCH',
	ORG_LEADS: 'ORG_LEADS',
	BLOG_FILTER: 'BLOG_FILTER',
	NEWS_FILTER: 'NEWS_FILTER',
}

export const enquiryFilters = {
	enquiry_id: 'enquiryIdFilter',
	enquiry_date: 'fromFilter',
	expiry_date: 'toFilter',
	product_name: 'productNameFilter',
	location: 'locationFilter',
	relationship_manager: 'relationshipManagerNameFilter',
	enquiry_status: 'enquiryStatusFilter',
	organisation: 'organisationFilter',
}

export const catalogFilters = {
	product_name: 'productNameFilter',
}

export const commodityFilters = {
	commodity_location: 'location',
	date: 'date',
	commodity_product: 'product_name',
	commodity_source: 'source',
}

export const bannerFilters = {
	banner_type: 'typeFilter',
	banner_role: 'roleFilter',
	banner_platform: 'platformFilter',
}

export const userFilters = ['userIdFilter', 'userNameFilter', 'userMobileFilter', 'userRoleFilter', 'userCompanyNameFilter']

export const enquiryOrderFilters = [
	'orderIdFilter',
	'orderDateFilter',
	'productNameFilter',
	'orderStatusFilter',
	'poNumberFilter',
	'organisationFilter',
	'relationshipManagerNameFilter',
]

export const userFilter = {
	user: 'userFilter',
	organisation: 'organisationFilter',
}

export const orgFilter = {
	organisation: 'organisationFilter',
	user: 'userFilter',
	pan: 'panFilter',
	org_role: 'roleFilter',
	category: 'categoryFilter',
	relationship_manager: 'relationshipManagerFilter',
	location: 'locationFilter'
}

export const LeadFilter = {
	lead_status: 'statusFilter',
	// pan: 'panFilter',
	manager: 'managerFilter',
	user: 'userFilter',
	lead_source: 'sourceFilter',
	region: 'regionFilter',
	industry: 'industryFilter',
	lead_type: 'leadTypeFilter',
	weight_from: 'fromWeightFilter',
	weight_to: 'toWeightFilter',
}

export const enquiryOrderFilter = {
	order_id: 'orderIdFilter',
	enquiry_id: 'enquiryIdFilter',
	listing_id: 'listingIdFilter',
	from: 'fromFilter',
	to: 'toFilter',
	order_date: 'orderDateFilter',
	product_name: 'productNameFilter',

	organisation: 'organisationFilter',
	relationship_manager: 'relationshipManagerNameFilter',
	location: 'locationFilter',
	po_number: 'poNumberFilter',
}

export const activeInvoiceFilter = {
	order_id: 'orderIdFilter',
	buyer_name: 'buyerNameFilter',
	invoice_number: 'originalInvoiceNumberFilter',
	vehicle_number: 'vehicleNumberFilter',
	driver_number: 'driverNumberFilter',
}

export const auctionFilter = {
	auction_id: 'auctionIdFilter',
	auction_opening_time: 'fromFilter',
	dispatch_address: 'dispatchLocationFilter',
	auction_closing_time: 'toFilter',
	product_name: 'productNameFilter',
	relationship_manager: 'relationshipManagerNameFilter',
	supplier_name: 'supplierNameFilter',
	auction_status: 'auctionStatusFilter',
}

export const pastReportsFilter = {
	name: 'name',
	pan_number: 'pan',
	excess_ctc_from: 'excessCTCFrom',
	excess_ctc_to: 'excessCTCTo',
	gm_from: 'gmFrom',
	gm_to: 'gmTo',
	gmv_from: 'gmvFrom',
	gmv_to: 'gmvTo',
	past_delays_from: 'pastDelaysFrom',
	past_delays_to: 'pastDelaysTo',
	payment_terms_from: 'paymentTermsFrom',
	payment_terms_to: 'paymentTermsTo',
	past_record_type: 'recordType',
}

export const cloudFactoryFilter = {
	inventory: 'inventory',
	partner_name: 'partnerName',
	factory_name: 'factoryName',
	process_name: 'processName',
	location: 'location',
}

export const cloudFactoryManageOrderFilter = {
	partner_name: 'partnerName',
	order_id: 'orderIdFilter',
	from: 'fromFilter',
	to: 'toFilter',
	input_order: 'inputOrder',
	output_order: 'outputOrder',
	enquiry_id: 'enquiryIdFilter',
}

export const ledgerFilter = {
	organisation: 'organisationFilter',
	from: 'fromFilter',
	to: 'toFilter',
	transaction_source: 'transactionSourceFilter',
	transaction_type: 'transactionTypeFilter',
	transaction_amount: 'transactionAmountFilter',
	invoice_number: 'originalInvoiceNumberFilter',
	zoho_status: 'zohoStatusFilter',
}

export const salesPurchaseFilter = {
	user: 'userFilter',
	from: 'fromFilter',
	to: 'toFilter',
	transaction_amount: 'transactionAmountFilter',
	invoice_number: 'originalInvoiceNumberFilter',
}

export const invoiceFilter = {
	full_name: 'userNameFilter',
	invoice_number: 'originalInvoiceNumberFilter',
}

export const underwritingFilter = {
	pan_number: 'panNumberFilter',
}

export const underwritingSearchFilter = {
	pan_number: 'panFilter',
}

export const collectionFilter = {
	user: 'userFilter',
	manager: 'managerFilter',
	status: 'statusFilter',
	region: 'regionFilter',
	platform: 'platformFilter',
}

export const blogFilter = {
	from: 'fromDate',
	to: 'toDate',
	heading: 'headingFilter',
	slug: 'slugFilter',
	source: 'sourceFilter',
	summary: 'summaryFilter',
	title: 'titleFilter',
}

export const newsFilter = {
	from: 'fromDate',
	to: 'toDate',
	heading: 'headingFilter',
	slug: 'slugFilter',
	source: 'sourceFilter',
	summary: 'summaryFilter',
	title: 'titleFilter',
}