export const capitalizeWord = (name = '') => name.substring(0, 1).toUpperCase() + name.substring(1).toLowerCase()

export const capitalizeEveryWord = (name = '') => {
	const words = name.split(' ')
	words.map((word, i) => {
		words[i] = capitalizeWord(word)
		return null
	})
	return words.join(' ')
}
