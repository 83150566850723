import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import Cookies from 'universal-cookie'
import { ORG_USER_ROLES, cookieKeys } from '@constants'
import { getUserByEmail, getUserByPhone } from '@data'
import { logout } from './logout'

export const getFirebaseUser = (navigate, userChanged) => {
	if (document) {
		// const cookieFirebaseUser = cookies.get(cookieKeys.firebaseUser)
		// if (cookieFirebaseUser) {
		// 	navigateUser(navigate, userChanged, cookieFirebaseUser)
		// 	return
		// }
		firebase.auth().onAuthStateChanged((firebaseUser) => {
			if (!firebaseUser) {
				if (navigate) {
					logout(navigate)
				}
				return
			}
			const cookies = new Cookies()
			cookies.set(cookieKeys.firebaseUser, firebaseUser, { path: '/' })

			navigateUser(navigate, userChanged, firebaseUser)
		})
	}
}

const navigateUser = (navigate, userChanged, firebaseUser) => {
	let callMethodType = null

	if (firebaseUser.phoneNumber) {
		callMethodType = getUserByPhone(firebaseUser.phoneNumber)
	} else if (firebaseUser.email) {
		callMethodType = getUserByEmail(firebaseUser.email)
	}

	if (callMethodType) {
		callMethodType
			.then((user) => {
				user = user.update({ uid: firebaseUser.uid })

				firebaseUser.getIdToken(true).then((token) => {
					const cookies = new Cookies()
					user = user.update({ authToken: token })

					if (!user.role) {
						user = user.update({ role: ORG_USER_ROLES.ORG_ADMIN })
					}
					// do not set whole user object as it is exceeding the 4096 kb limit to save in the cookies
					cookies.set(
						cookieKeys.user,
						{
							role: user.role,
							user_id: user.user_id,
							full_name: user.full_name,
							mobile_number: user.mobile_number,
							organisation_id: user.organisation_id,
							organisation_name: user.organisation_name,
							org_created: user.org_created,
							authToken: token,
							uId: firebaseUser.uid,
						},
						{ path: '/' }
					)

					// storeUserData(user, token)
					userChanged(user)
				})
				// firebase.auth().onIdTokenChanged(async (_user) => {
				// 	const token = await _user?.getIdToken()
				// 	console.log('id token change', token, new Date())
				// 	storeUserData(user, token)
				// })
			})
			.catch((err) => {
				if (navigate) {
					logout(navigate)
				}
			})
	}
}

// const storeUserData = (user) => {
// 	const cookies = new Cookies()

// 	if (!user.role) {
// 		user.role = roles.buyer
// 	}

// 	cookies.set(cookieKeys.user, user, { path: '/' })
// }
